import BottomMenu from "components/global-components/common/BottomMenu";
import BazAiNav from "components/mobile-components/Common/BazAiNav";
import { getUserBusiness } from "config/user";
import React, { useEffect, useRef, useState } from "react";
import Business from "services/Business";
import Supplier from "services/Supplier";
import Banner from "components/mobile-components/ProductDetails/Banner";
import SuppliersDetails from "components/mobile-components/SuppliersDetails/SuppliersDetails";
import Product from "services/Product";
import { IonLoading } from "@ionic/react";
import debounce from "lodash.debounce";
let settinges = {
	nameShow: false,
	titleShow: true,
	skuShow: false,
	priceShow: true,
	oldPriceShow: false,
	distShow: false,
	profitShow: false,
	sellPriceShow: false,
	supplierNameShow: false,
	link: "product-details",
};

export default function SuppliersPage() {
	const longLat = {
		long: getUserBusiness()?.longitude,
		lati: getUserBusiness()?.latitude,
	};
	// let { id } = getUserBusiness();
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [selectedSupplier, setSelectedSupplier] = useState({});
	const [suppliers, setSuppliers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [product, setProduct] = useState([]);
	const [productCount, setProductCount] = useState(null);
	const [searchQ, setSearchQ] = useState("");
	const [selectedBrand, setSelectedBrand] = useState("");
	const [productBrands, setProductBrands] = useState([]);
	const [sellerProductLoader, setSellerProductLoader] = useState(false);
	const [pageCount, setPageCount] = useState(2);
	const [tempSearchParams, setTempSearchParams] = useState(false);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);

	const getSuppliers = async () => {
		setLoading(true);
		const response = await Business.getSuppliers(
			longLat.long,
			longLat.lati,
			1,
			14
		);
		if (response) {
			setSuppliers(response.results);
			setSelectedSupplier(response.results[0]); // Select the first procurement
			setSelectedIndex(0);
			getProductBrands(response.results[0]?.id);
		}
		setLoading(false);
	};

	useEffect(() => {
		getSuppliers();
	}, []);

	const handleSupplierSelect = (supplier, index) => {
		setSelectedSupplier(supplier);
		setSelectedIndex(index);
	};

	let slideData = selectedSupplier?.images?.length
		? [...selectedSupplier.images]
		: ["/assets/images/supplier_big_default.png"];

	let productData = async () => {
		let response = await Supplier.supplierProducts(selectedSupplier.id);
		if (response.status === 200) {
			setProduct(response.results);
			setProductCount(response?.count);
		} else {
		}
	};
	useEffect(() => {
		productData();
	}, [selectedSupplier.id]);

	async function getProductBrands(id) {
		try {
			const response = await Product.productBrandsSeller(id);
			if (response.status === 200) {
				setProductBrands(response.results);
			}
		} catch (error) {
			console.log(error);
		}
	}

	async function getProductsFromBrands(Bid) {
		setPageCount(2);
		setSellerProductLoader(true);
		setSelectedBrand(Bid);
		setProduct([]);
		try {
			if (searchQ) {
				let response = await Product.singleProductSearch(
					Bid,
					selectedSupplier.id,
					searchQ
				);
				if (response.status === 200) {
					setProduct(response.results);
					setSellerProductLoader(false);
				} else {
					setSellerProductLoader(false);
				}
			} else {
				const response = await Product.singleBrandProductsSeller(
					Bid,
					selectedSupplier.id
				);
				if (response.status === 200) {
					setProduct(response?.results);
					setSellerProductLoader(false);
				} else {
					setSellerProductLoader(false);
				}
			}
		} catch (error) {
			console.log(error);
		}
	}
	async function singleProductSearch() {
		setHasMoreData(true);
		setReachedEnd(false);
		setTempSearchParams(searchQ);
		setPageCount(2);
		try {
			let response;
			if (selectedBrand) {
				response = await Product.singleProductSearch(
					selectedBrand,
					selectedSupplier.id,
					searchQ
				);
			} else {
				response = await Product.sellerProductSearch(
					selectedSupplier.id,
					searchQ
				);
			}

			if (response.status === 200) {
				setProduct(response.results);
			}
		} catch (error) {
			console.log(error);
		}
	}

	const tableRef = useRef(null);
	const previousScrollPosition = useRef(0);
	let currentPage = 1;
	const handleScroll = debounce(async () => {
		const container = tableRef.current;
		if (!container || loading) return;

		const { scrollTop, scrollHeight, clientHeight } = container;

		// Determine scroll direction
		const isScrollingDown = scrollTop > previousScrollPosition.current;
		previousScrollPosition.current = scrollTop;

		if (isScrollingDown && scrollTop + clientHeight >= scrollHeight - 20) {
			try {
				currentPage += 1;

				const response = await Business.getSuppliers(
					longLat.long,
					longLat.lati,
					currentPage,
					20
				);
				if (response) {
					setSuppliers((prevOrders) => [
						...prevOrders,
						...response.results,
					]);
				}
			} catch (error) {
				console.error("Failed to fetch orders:", error);
			} finally {
				setLoading(false);
			}
		}
	}, 300);

	// Attach scroll listener
	useEffect(() => {
		const container = tableRef.current;
		if (container) {
			container.addEventListener("scroll", handleScroll);
			return () => container.removeEventListener("scroll", handleScroll);
		}
	}, [loading]);

	const productRef = useRef(null);
	const previousScrollPositionProduct = useRef(0);
	let currentPageProduct = 1;
	const handleScrollProduct = debounce(async () => {
		const container = productRef.current;
		if (!container || loading) return;

		const { scrollTop, scrollHeight, clientHeight } = container;
		const isScrollingDown = scrollTop > previousScrollPositionProduct.current;
		previousScrollPositionProduct.current = scrollTop;

		if (isScrollingDown && scrollTop + clientHeight >= scrollHeight - 20) {
			try {
				currentPageProduct += 1;
				let response = await Supplier.supplierProducts(
					selectedSupplier.id,
					currentPageProduct,
					10
				);
				if (response.status === 200) {
					setProduct((prevOrders) => [...prevOrders, ...response.results]);
					setProductCount(response?.count);
				} else {
				}
			} catch (error) {
				console.error("Failed to fetch orders:", error);
			} finally {
				setLoading(false);
			}
		}
	}, 300);

	// Attach scroll listener
	useEffect(() => {
		const container = productRef.current;

		if (container) {
			container.addEventListener("scroll", handleScrollProduct);
			return () =>
				container.removeEventListener("scroll", handleScrollProduct);
		}
	}, [handleScrollProduct]);

	return (
		<div className='px-8 pb-5 mx-auto'>
			<BazAiNav title='Suppliers' />
			{loading ? (
				<IonLoading
					isOpen={loading}
					message={"Please wait..."}
					spinner='circles'
				/>
			) : suppliers?.length === 0 ? (
				<div className=' flex justify-center items-center text-gray-500 font-bold text-2xl'>
					No data found!
				</div>
			) : (
				<div className='w-full flex lg:flex-row flex-col space-x-5'>
					<div className='lg:w-1/2 w-full pb-2.5'>
						{/* Left Pane (Table) */}
						<div
							className='bg-[#ffffff] rounded-[10px] mt-20'
							style={{
								boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
							}}>
							{/* <h3 className='text-222222 font-bold text-24 py-2 px-4'>
								Suppliers
							</h3> */}
							<div
								className={`border border-gray-300 rounded-lg p-4 ${
									suppliers?.length > 15 && "h-[780px]"
								} overflow-y-auto`}
								ref={tableRef}>
								<div className=''>
									<table className='min-w-full divide-y divide-gray-200'>
										<thead>
											<tr className='bg-gray-500 text-white'>
												<th className='text-left px-4 py-2'>S/N</th>
												<th className='text-left px-4 py-2'>
													Supplier Name
												</th>
												<th className='text-left px-4 py-2'>
													Address
												</th>
												<th className='text-left px-4 py-2'>
													Status
												</th>
											</tr>
										</thead>
										<tbody>
											{suppliers?.map((supplier, index) => (
												<tr
													key={supplier?.id}
													className={`${
														selectedIndex == index
															? "bg-black-100"
															: index % 2 === 0
															? "bg-white"
															: "bg-gray-50"
													} h-12 cursor-pointer transition-colors duration-200`}
													onClick={() => {
														setProduct([]);
														setSelectedIndex(index);
														handleSupplierSelect(supplier, index);
													}}>
													<td className='text-black px-4 py-2'>
														{index + 1}
													</td>
													<td className='text-black px-4 py-2 text-center'>
														{supplier?.business_name}
													</td>
													<td className='text-black px-4 py-2'>
														{supplier?.address_line}
													</td>
													<td className='px-4 py-2'>
														<span
															className={`${
																supplier?.business_status ===
																"Verified"
																	? "bg-green-100 text-green-800"
																	: supplier?.business_status ===
																	  "Pending"
																	? "bg-yellow-100 text-yellow-800"
																	: supplier?.business_status ===
																	  "Unverified"
																	? "bg-red-100 text-red-800"
																	: "bg-gray-100 text-gray-800"
															} rounded-full px-3 py-1 text-sm`}>
															{supplier?.business_status
																.charAt(0)
																.toUpperCase() +
																supplier?.business_status.slice(
																	1
																)}
														</span>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>

						{/* Right Pane (Details) */}
					</div>
					<div
						id='main-content'
						className='rounded-lg lg:w-1/2 w-full  mt-20   bg-white'>
						<div className='border border-gray-300 rounded-lg p-4'>
							<Banner slideData={slideData} />
							<SuppliersDetails
								supplierInfo={selectedSupplier}
								product={product}
								settinges={settinges}
								productCount={productCount}
								productBrands={productBrands}
								getProductBrands={productData}
								getProductsFromBrands={getProductsFromBrands}
								setSearchQ={setSearchQ}
								searchQ={searchQ}
								singleProductSearch={singleProductSearch}
								setSelectedBrand={selectedSupplier?.id}
								from='brandsProduct'
								brandId={selectedSupplier?.id}
								ref={productRef} // <-- Correctly pass the ref here
							/>
						</div>
					</div>
					<BottomMenu />
				</div>
			)}
		</div>
	);
}
