import React, { useState } from "react";
import BannerSlider from "./BannerSlider";
import "./style.scss";
import PhoneNumber from "pages/LandingPageV2/PhoneNumber";

export default function BannerNumberCheck() {
	const [countryCode, setCountryCode] = useState("+1");
	const [country, setCountry] = useState("United States");
	const [phoneNo, setPhoneNo] = useState("");
	var sSize = window.matchMedia("(max-width: 599px)");
	return (
		// <div className='relative '>
		// 	<div className='pb-[70px] sm:pb-[70px] container m-auto  bg-F2F3F7'>
		// 		<div className='flex items-center md:space-x-0 lg:space-x-10 relative z-10 slider-wrapper'>
		// 			<div className='sm:w-5/12 w-8/12 slider-area'>
		// 				<BannerSlider />
		// 			</div>

		// 		</div>

		// 	</div>
		// </div>
		<div className='container w-full h-screen flex items-center justify-center lg:flex-row flex-col overflow-y-scroll'>
			<PhoneNumber
				sSize={sSize}
				phoneNo={phoneNo}
				setPhoneNo={setPhoneNo}
				country={country}
				setCountry={setCountry}
				countryCode={countryCode}
				setCountryCode={setCountryCode}
			/>
		</div>
	);
}
