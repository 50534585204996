import React, { useState } from "react";
import Footer from "./Footer";
import Navigation from "./Navigation";
import Partners from "./Partners";
import OurTeam from "./OurTeam";
import BannerTokenSubmit from "./BannerToken";

export default function TokenSubmitPage() {
	const [scrolling, setScrolling] = useState(false);

	return (
		<>
			<section style={{ background: "#f2f3f7" }}>
				<Navigation scrolling={scrolling ? "isScrolled" : ""} />
				<BannerTokenSubmit />
				{/* <OurTeam />
				<Partners /> */}
				<Footer />
			</section>
		</>
	);
}
