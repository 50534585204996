import BazAiNav from "components/mobile-components/Common/BazAiNav";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-pro-sidebar/dist/css/styles.css";
import { useNavigate } from "react-router-dom";
import { getUserBusiness } from "config/user";
import { getLanguage } from "config/language";
import Notification from "services/Notification";
import { setNotification } from "redux/container/notificationSlice";

import BottomMenu from "components/global-components/common/BottomMenu";

export default function BazStorePage() {
	const contentRef = useRef();
	const messageData = useSelector((state) => state?.bazAi?.data);

	const [notificationList, setNotificationList] = useState([]);
	const [notificationType, setNotificationType] = useState("customer");
	const dispatch = useDispatch();
	const [notificationCount, setNotificationCount] = useState("");
	const business = getUserBusiness();
	const navigate = useNavigate();

	var windowSize = window.matchMedia("(max-width: 1100px)");

	const [showLeft, setShowLeft] = useState(!windowSize.matches ? true : false);
	const [showRight, setShowRight] = useState(false);

	const scrollToBottom = () => {
		if (contentRef.current) {
			contentRef.current.scrollToBottom();
		}
	};
	useEffect(() => {
		scrollToBottom();
	}, [messageData.length]);

	const menuIconClick = () => {
		setShowLeft(!showLeft);
	};

	let textContentRef = useRef();

	let getNotificationList = async () => {
		let response = await Notification.getProcurementNotifications();
		if (response.success) {
			setNotificationCount(response?.unread);
			dispatch(setNotification([...notificationList, ...response?.results]));
			setNotificationList([...notificationList, ...response?.results]);
		}
	};
	let getNotificationListDetails = async (selectedNotification) => {
		navigate(
			`/procurement-order-details/${Number(
				selectedNotification?.service_field_id
			)}/${business?.role}`
		);
		let response = await Notification.getProcurementNotificationsDetails(
			selectedNotification?.id
		);
		if (response.success) {
			dispatch(setNotification([...notificationList, ...response?.results]));
			setNotificationList([...notificationList, ...response?.results]);
		}
	};

	useEffect(() => {
		getNotificationList();
	}, [notificationType]);

	let data = [
		{
			name: "EkkBaz",
			image: "/assets/images/baz-store/ekkbaz.png",
			description:
				"EkkBaz is a platform that allows you to manage your business.",
			status: "Connected",
		},
		{
			name: "Bland AI",
			image: "/assets/images/baz-store/bland-ai.png",
			description:
				"Bland AI is a platform that allows you to manage your business.",
			status: "Connected",
		},
		{
			name: "WhatsApp",
			image: "/assets/images/baz-store/whatsapp.png",
			description:
				"WhatsApp is a platform that allows you to manage your business.",
			status: "Coming Soon",
		},
		{
			name: "Twilio",
			image: "/assets/images/baz-store/twilio.jpg",
			description:
				"Twilio is a platform that allows you to manage your business.",
			status: "Coming Soon",
		},
	];

	return (
		<div
			className={`relative bg-white h-screen flex flex-col overflow-y-auto`}>
			<BazAiNav title='Baz Store' />

			<div className='container mx-auto px-4 py-8 mt-14'>
				<div className='grid grid-cols-3 gap-4'>
					{data.map((app, index) => (
						<div key={index} className='bg-white rounded-lg p-4'>
							<div className='flex'>
								<img
									src={app.image}
									alt={app.name}
									className=' w-[100px] mb-3'
								/>
								<div className='ml-3'>
									<h3 className='text-gray-800 font-bold text-lg mb-2'>
										{app.name}
									</h3>
									<span
										className={`text-sm px-3 py-1 rounded-full ${
											app.status === "Connected"
												? "bg-green-100 text-green-800"
												: "bg-gray-100 text-gray-800"
										}`}>
										{app.status}
									</span>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>

			<BottomMenu />
		</div>
	);
}
