import React, { useRef, useState } from "react";
import {
	withGoogleMap,
	GoogleMap,
	withScriptjs,
	Marker,
} from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import { useEffect } from "react";
import {
	getCreateUserBusiness,
	getUser,
	setCreateUserBusiness,
} from "config/user";
import { COUNTRYINFO } from "data/country";
import { useDispatch } from "react-redux";
import { setLocation, setMapLocation } from "redux/container/userSlice";
import Search from "assets/images/icon/svg/Search";
import { IonButton } from "@ionic/react";
import { baseUrl } from "config/apiUrl";

Geocode.setApiKey(baseUrl.mapKey);
// Geocode.enableDebug();

export default function MapFunctional({ center, height, zoom, location }) {
	let user = getUser();
	let _u = localStorage.getItem("setUser");
	let userParse = JSON.parse(_u)?.user;
	let dispatch = useDispatch();
	let autocompleteRef = useRef(null);
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [area, setArea] = useState("");
	const [state, setState] = useState("");
	const [country, setCountry] = useState("");
	const [mapPosition, setMapPosition] = useState({
		lat: center.lat,
		lng: center.lng,
	});
	const [markerPosition, setMarkerPosition] = useState({
		lat: center.lat,
		lng: center.lng,
	});
	let userC = user ? user.Country : userParse.Country;
	let userCountry = Object.values(COUNTRYINFO).find(
		(el) => el.name === userC
	).countryShortName;

	useEffect(() => {
		Geocode.fromLatLng(mapPosition.lat, mapPosition.lng).then(
			(response) => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = getCity(addressArray),
					area = getArea(addressArray),
					state = getState(addressArray),
					country = getCountry(addressArray);

				// console.log("city", city, area, state);
				setAddress(address ?? "");
				setCity(city ?? "");
				setArea(area ?? "");
				setState(state ?? "");
			},
			(error) => {
				console.error(error);
			}
		);
	}, []);

	//get city
	const getCity = (addressArray) => {
		let city = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (
				(addressArray[i].types[0] &&
					"sublocality_level_1" === addressArray[i].types[0]) ||
				(addressArray[i].types[0] &&
					"locality" === addressArray[i].types[0])
			) {
				city = addressArray[i].long_name;
				return city;
			}
		}
	};

	//get area
	const getArea = (addressArray) => {
		let area = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0]) {
				for (let j = 0; j < addressArray[i].types.length; j++) {
					if (
						"sublocality_level_1" === addressArray[i].types[j] ||
						"locality" === addressArray[i].types[j]
					) {
						area = addressArray[i].long_name;
						return area;
					}
				}
			}
		}
	};

	//get state
	const getState = (addressArray) => {
		let state = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (
				addressArray[i].types[0] &&
				"administrative_area_level_1" === addressArray[i].types[0]
			) {
				state = addressArray[i].long_name;
				return state;
			}
		}
	};

	//get postal code
	const getPostalCode = (addressArray) => {
		let postalCode = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types.includes("postal_code")) {
				postalCode = addressArray[i].long_name;
				break;
			}
		}
		return postalCode;
	};

	//get country
	const getCountry = (addressArray) => {
		let country = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (
				addressArray[i].types[0] &&
				"country" === addressArray[i].types[0]
			) {
				country = addressArray[i].long_name;
				return country;
			}
		}
	};

	// onPlace selece
	const onPlaceSelected = (place) => {
		const address = place.formatted_address,
			addressArray = place.address_components,
			city = getCity(addressArray),
			area = getArea(addressArray),
			state = getState(addressArray),
			country = getCountry(addressArray),
			postalCode = getPostalCode(addressArray),
			latValue = place.geometry.location.lat(),
			lngValue = place.geometry.location.lng();
		// Set these values in the state.
		setAddress(address ?? "");
		setCity(city ?? "");
		setArea(area ?? "");
		setState(state ?? "");
		setCountry(country ?? "");
		// setPostalCode(postalCode ?? "");
		setMapPosition({
			lat: latValue,
			lng: lngValue,
		});
		setMarkerPosition({
			lat: latValue,
			lng: lngValue,
		});
		let locationBuilder = {
			address,
			city,
			area,
			state,
			country,
			postalCode,
			lat: latValue,
			lng: lngValue,
		};
		dispatch(setMapLocation(locationBuilder));
		const business = getCreateUserBusiness();
		business.address_line = address;
		business.postal_code = postalCode;
		business.city = city;
		business.state = state;
		business.country = country;
		business.long_lat = {
			type: "Point",
			coordinates: [lngValue, latValue],
		};
		setCreateUserBusiness(business);
	};

	//on marker dragend
	const onMarkerDragEnd = (event) => {
		let newLat = event.latLng.lat(),
			newLng = event.latLng.lng(),
			addressArray = [];

		Geocode.fromLatLng(newLat, newLng).then(
			(response) => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = getCity(addressArray),
					area = getArea(addressArray),
					state = getState(addressArray),
					postalCode = getPostalCode(addressArray);
				setAddress(address ?? "");
				setCity(city ?? "");
				setArea(area ?? "");
				setState(state ?? "");
				setMapPosition({
					lat: newLat,
					lng: newLng,
				});
				setMarkerPosition({
					lat: newLat,
					lng: newLng,
				});
				let locationBuilder = {
					address,
					city,
					area,
					state,
					lat: newLat,
					lng: newLng,
				};
				dispatch(
					setLocation({
						type: "Point",
						coordinates: [newLat, newLng],
					})
				);
				const business = getCreateUserBusiness();
				business.address_line = address;
				business.postal_code = postalCode;
				business.city = city;
				business.state = state;
				business.long_lat = {
					type: "Point",
					coordinates: [newLng, newLat],
				};
				setCreateUserBusiness(business);
				dispatch(setMapLocation(locationBuilder));
			},
			(error) => {
				console.error(error);
			}
		);
	};

	function handleClick(event) {
		var newLat = event.latLng.lat(),
			newLng = event.latLng.lng();

		Geocode.fromLatLng(newLat, newLng).then(
			(response) => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = getCity(addressArray),
					area = getArea(addressArray),
					postalCode = getPostalCode(addressArray),
					state = getState(addressArray),
					country = getCountry(addressArray);

				setAddress(address ?? "");
				setCity(city ?? "");
				setArea(area ?? "");
				setState(state ?? "");
				setCountry(country ?? "");
				setMapPosition({
					lat: newLat,
					lng: newLng,
				});
				setMarkerPosition({
					lat: newLat,
					lng: newLng,
				});
				let locationBuilder = {
					address,
					city,
					area,
					state,
					country,
					lat: newLat,
					lng: newLng,
				};
				dispatch(
					setLocation({
						type: "Point",
						coordinates: [newLat, newLng],
					})
				);
				const business = getCreateUserBusiness();
				business.address_line = address;
				business.postal_code = postalCode;
				business.city = city;
				business.state = state;
				business.country = country;
				business.long_lat = {
					type: "Point",
					coordinates: [newLng, newLat],
				};
				dispatch(setMapLocation(locationBuilder));
			},
			(error) => {
				console.error(error);
			}
		);
	}
	const AsyncMap = withScriptjs(
		withGoogleMap((props) => (
			<GoogleMap
				// google={this.props.google}
				onClick={(e) => handleClick(e)}
				onRightClick={props.onMapClick}
				defaultZoom={zoom}
				defaultOptions={{ fullscreenControl: false, mapTypeControl: false }}
				defaultCenter={{
					lat: mapPosition.lat,
					lng: mapPosition.lng,
				}}>
				{/* For Auto complete Search Box */}
				<div className='flex top-[66px] left-0 px-4 absolute w-full'>
					<Autocomplete
						ref={autocompleteRef}
						style={{
							width: "100%",
							height: "50px",
							paddingLeft: "16px",
							marginTop: "5px",
							marginBottom: "0px",
							borderRadius: "7px",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
							marginRight: "10px",
						}}
						onPlaceSelected={onPlaceSelected}
						options={{
							types: ["geocode"],
						}}
						defaultValue={`${location.address ?? ""}`}
					/>
					<IonButton
						color='success'
						className='h-[50px] w-[50px] rounded-[7px]'>
						<Search />
					</IonButton>
				</div>

				{/*Marker*/}
				<Marker
					// google={this.props.google}
					name={"Dolores park"}
					draggable={true}
					onDragEnd={onMarkerDragEnd}
					position={{
						lat: markerPosition.lat,
						lng: markerPosition.lng,
					}}
					icon={{
						url: require("assets/images/locationPin.png"),
					}}
				/>
				<Marker />
			</GoogleMap>
		))
	);
	let map;
	if (center.lat !== undefined) {
		map = (
			<div>
				<AsyncMap
					googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${baseUrl.mapKey}&libraries=places`}
					loadingElement={<div style={{ height: `100%` }} />}
					containerElement={<div style={{ height: height }} />}
					mapElement={<div style={{ height: `100%` }} />}
				/>
			</div>
		);
	} else {
		map = <div style={{ height: height }} />;
	}
	return map;
}
