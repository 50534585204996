import { setProductBrand } from "config/user";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	productCreate,
	setCreateState,
	setMyBrandState,
} from "redux/container/productSlice";

export default function SingleBrand({ item }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let product = useSelector((state) => state.product.data);

	return (
		<div
			className='flex items-center ease-in duration-100 hover:bg-e6e6e6 mb-[5px] py-[5px] px-4 cursor-pointer relative'
			onClick={() => {
				dispatch(setCreateState(true));
				dispatch(setMyBrandState(false));
				dispatch(
					productCreate({
						...product,
						brandId: item.id,
					})
				);
				setProductBrand(item);
				navigate(`/assets/`);
			}}>
			{item?.images?.length ? (
				<div className='h-10 w-10 rounded-full overflow-hidden'>
					<img src={item?.images[0]} alt='logo'></img>
				</div>
			) : (
				<div className='h-10 w-10 flex justify-center items-center bg-black-333 rounded-full'>
					<h3 className='font-bold text-24 text-white'>
						{item?.brand_name[0].toUpperCase()}
					</h3>
				</div>
			)}

			<div className='ml-4'>
				<h4 className='text-14 text-black-1000 font-bold pb-[2px]'>
					{item?.brand_name}
				</h4>
				<p className='text-10 text-black-500'>{item?.owner_name}</p>
			</div>
			<div className='mr-4 absolute right-4 top-4'>
				<p className='text-10 text-black-500'>
					{item?.role === "Imported" ? "Imported" : "Own Brand"}
				</p>
			</div>
		</div>
	);
}
