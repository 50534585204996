import React from "react";
import parse from "html-react-parser";
import QtyController from "./QtyController";
import { IonCol, IonSpinner } from "@ionic/react";
import { Link } from "react-router-dom";

export default function ReceiverDataView({
	data,
	updateLastDataHandler,
	editEmail,
	openEditEmail,
	handleKeyDown,
	handleChange,
	height,
	emailSendHandler,
	closeEditEmail,
	ekkLanguage,
	loading,
	textareaRef,
}) {
	if (data?.cardType === "infoCard") {
		return (
			<div className='flex-1 flex'>
				{data.loading ? (
					<div className='flex items-center'>
						<h3
							className='font-bold text-16'
							style={{ color: "#555151" }}>
							Thinking
						</h3>
						<div className='loader'></div>
					</div>
				) : (
					<p
						className='font-semibold text-15 bg-white text-black-700 p-3 mb-[5px] md:min-w-[450px]'
						style={{
							borderRadius: "0px 15px 15px 15px",
							boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
						}}>
						<span className='block'>
							{data?.message.split("\n").map((line, index) => (
								<React.Fragment key={index}>
									{parse(line)}
									<br />
								</React.Fragment>
							))}
						</span>
					</p>
				)}
			</div>
		);
	} else if (data?.cardType === "analysisCard") {
		return (
			<div>
				{data.loading ? (
					<div className='loader'></div>
				) : (
					<p
						className='font-semibold text-15  bg-white text-black-700 p-3 mb-[5px]'
						style={{
							borderRadius: "0px 15px 15px 15px",
							boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
						}}>
						{
							<div className='mb-3'>
								<span
									className='block font-bold pb-1'
									style={{ color: "#808080" }}>
									Supplier Price Analysis:
								</span>
								<span style={{ color: "#808080" }}>
									{data?.suppliers?.map((item, index) => (
										<React.Fragment key={index}>
											{`${index + 1})${item?.supplier}: ${
												data?.product?.country
													?.currency_symbol_native
											}${item?.price}`}
											<br />
										</React.Fragment>
									))}
								</span>
							</div>
						}
						{data?.corns?.length && (
							<>
								<span
									className='block font-bold pb-1'
									style={{ color: "#808080" }}>
									Risk Analysis:
								</span>
								<span style={{ color: "#808080" }}>
									{data?.corns?.map((line, index) => (
										<React.Fragment key={index}>
											{`${index + 1})${line}`}
											<br />
										</React.Fragment>
									))}
								</span>
							</>
						)}
					</p>
				)}
			</div>
		);
	} else if (data?.cardType === "productCard") {
		return (
			<div>
				{data.loading ? (
					<div className='loader'></div>
				) : (
					<p
						className='font-semibold min-w-[200px] bg-white text-black-700 p-3 mb-[5px]'
						style={{
							borderRadius: "0px 15px 15px 15px",
							boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
						}}>
						<span className='mb-3 block'>
							{data?.message.split("\n").map((line, index) => (
								<React.Fragment key={index}>
									{line}
									<br />
								</React.Fragment>
							))}
						</span>

						{data?.corns?.length && data?.orderState !== "hide" && (
							<div className='pb-3'>
								<QtyController
									data={data}
									item={data?.productQty}
									initQty={data?.productInitQty}
									updateLastDataHandler={updateLastDataHandler}
								/>
							</div>
						)}
					</p>
				)}
			</div>
		);
	} else if (data?.cardType === "cadCard") {
		return (
			<div>
				{data.loading ? <div className='loader'></div> : <div></div>}
			</div>
		);
	} else if (data?.cardType === "emailCard2") {
		return (
			<div className='flex-1'>
				{data.loading ? (
					<div className='loader'></div>
				) : (
					<div className='flex-1 flex'>
						{data.loading ? (
							<div className='flex items-center'>
								<h3
									className='font-bold text-16'
									style={{ color: "#555151" }}>
									Thinking
								</h3>
								<div className='loader'></div>
							</div>
						) : (
							<div
								className='font-semibold text-15 bg-white text-black-700 p-3 mb-[5px] min-w-[200px]'
								style={{
									borderRadius: "0px 15px 15px 15px",
									boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
								}}>
								<h1 className='text-left font-bold text-16 mb-5'>
									Email Invitation
								</h1>
								{/* <span className='block'>
								{data?.message.split("\n").map((line, index) => (
									<React.Fragment key={index}>
										{parse(line)}
										<br />
									</React.Fragment>
								))}
							</span> */}

								<p className='font-semibold text-15 bg-white text-black-700 mb-[5px] md:min-w-[450px]'>
									<span className='block'>
										{data?.message.split("\n").map((line, index) => (
											<React.Fragment key={index}>
												{parse(line)}
												<br />
											</React.Fragment>
										))}
									</span>
								</p>
							</div>
						)}
					</div>
				)}
			</div>
		);
	} else if (data?.cardType === "emailCard") {
		return (
			<div className='flex-1'>
				{data.loading ? (
					<div className='loader'></div>
				) : (
					<div className='flex-1 flex'>
						{data.loading ? (
							<div className='flex items-center'>
								<h3
									className='font-bold text-16'
									style={{ color: "#555151" }}>
									Thinking
								</h3>
								<div className='loader'></div>
							</div>
						) : (
							<div
								className='font-semibold text-15 bg-white text-black-700 p-3 mb-[5px] w-full'
								style={{
									borderRadius: "0px 15px 15px 15px",
									boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
								}}>
								<h1 className='text-left font-bold text-16 mb-5'>
									Send Email
								</h1>
								{/* <span className='block'>
								{data?.message.split("\n").map((line, index) => (
									<React.Fragment key={index}>
										{parse(line)}
										<br />
									</React.Fragment>
								))}
							</span> */}

								<div>
									{!editEmail ? (
										<p onClick={!data?.mailSend && openEditEmail}>
											{data?.mailContent
												?.split("\n")
												.map((str, index) => (
													<React.Fragment key={index}>
														{str}
														<br />
													</React.Fragment>
												))}
										</p>
									) : (
										<div>
											<textarea
												// readOnly={
												// 	messageData[messageData?.length - 1]
												// 		?.loading
												// }
												onKeyDown={handleKeyDown}
												style={{
													minHeight: "300px",
													height: `${height}px`,
													// overflow:
													// 	height === 120 ? "auto" : "hidden",
													resize: "none",
													width: "100%",
													padding: "10px",
													paddingTop: "15px",
													boxSizing: "border-box",
													paddingRight: 35,
													borderRadius: 15,
													overflowY: "scroll",
												}}
												value={data?.mailContent}
												onChange={(e) => handleChange(e, data)}
												type='text'
												placeholder='Write your email'
												className='gpt-text-area py-3.5 pr-10 px-3 appearance-none block mr-1 border leading-tight focus:outline-1 bg-white focus:bg-white text-14 w-full'
											/>
										</div>
									)}
								</div>
								{!data?.mailSend && (
									<div className='flex mt-5 justify-center'>
										<div
											className=' bg-success rounded-[15px] px-3 py-2  flex items-center mx-2 justify-center'
											onClick={() => emailSendHandler(data)}>
											<h3 className='text-white font-bold text-15'>
												Send Email
											</h3>
										</div>

										{editEmail && (
											<div
												className=' bg-primary rounded-[15px] px-3 py-2  flex items-center mx-2  justify-center'
												onClick={() => closeEditEmail(data)}>
												<h3 className='text-white font-bold text-15'>
													Close
												</h3>
											</div>
										)}
									</div>
								)}
							</div>
						)}
					</div>
				)}
			</div>
		);
	} else if (data?.cardType === "bomCard") {
		return (
			<div>
				{data.loading ? (
					<div className='loader'></div>
				) : (
					<div className='flex-1 flex'>
						{data.loading ? (
							<div className='flex items-center'>
								<h3
									className='font-bold text-16'
									style={{ color: "#555151" }}>
									Thinking
								</h3>
								<div className='loader'></div>
							</div>
						) : (
							<div
								className='font-semibold text-15 bg-white text-black-700 p-3 mb-[5px] md:min-w-[750px]'
								style={{
									borderRadius: "0px 15px 15px 15px",
									boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
								}}>
								<h1 className='text-left font-bold text-16 mb-5'>
									Bill Of Material
								</h1>

								<div>
									<div className=''>
										<table
											className='table-auto w-full bg-white'
											style={{
												boxShadow:
													"0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
											}}>
											{/* <thead>
													<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
														<th className='border p-1 font-bold text-12'>
															S/N
														</th>
														<th className='border  font-bold text-12'>
															Material Name
														</th>
														<th className='border p-1 font-bold text-12'>
															Qty
														</th>
														<th className='border p-1 font-bold text-12'>
															Measurement
														</th>
													</tr>
												</thead> */}
											<thead>
												<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
													<th className='border p-1 font-bold text-16'>
														S/N
													</th>
													<th className='border p-1 font-bold text-16 text-left'>
														Component Name
													</th>
													{/* <th className='border p-1 font-bold text-16'>
															Quantity
														</th> */}
												</tr>
											</thead>
											<tbody>
												{data?.bomJson?.length
													? data?.bomJson?.map(
															(component, index) => (
																<tr
																	key={`${component?.name}-${index}`}
																	className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'>
																	<td
																		// rowSpan={
																		// 	components.length
																		// }
																		// style={{
																		// 	verticalAlign:
																		// 		"middle",
																		// }}
																		className='border p-2 w-14 font-semibold text-14'>
																		{index + 1}
																	</td>

																	<td className='border p-2 w-14 font-semibold text-14 text-left'>
																		{component?.name}
																	</td>
																	{/* <td className='border p-2 w-14 font-semibold text-14'>
																			1
																		</td> */}
																</tr>
															)
													  )
													: undefined}
											</tbody>
											{/* <tbody>
													{data?.bomJson?.CADFileData?.length
														? data?.bomJson?.CADFileData?.map(
																(el, i) => {
																	return (
																		<tr
																			className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'
																			key={i}>
																			<td className='border p-2 w-14 font-semibold text-10'>
																				{digitChanger(
																					i + 1
																				)}
																			</td>
																			<td className='border font-semibold p-2 text-10'>
																				{el.materialName}
																			</td>
																			<td className='border font-semibold p-2 text-10'>
																				{el.materialName}
																			</td>
																		</tr>
																	);
																}
														  )
														: ""}
												</tbody> */}
										</table>
										{loading ? (
											<div className='flex items-center justify-center h-[156px] m-auto'>
												{" "}
												<IonSpinner name='crescent'></IonSpinner>
											</div>
										) : (
											""
										)}
										{data?.bomJson?.CADFileData?.length === 0 && (
											<>
												<IonCol size='2'></IonCol>
												<IonCol size='8'>
													<div
														className='text-center flex items-center justify-center'
														style={{
															height: "calc(100vh - 420px)",
														}}>
														<div>
															<img
																className='m-auto mb-[10px] mt-5'
																src='/assets/images/not-found-file.png'
																alt='emptyCard'
															/>
															<p className='text-16 font-normal text-222222 mb-[35px]'>
																{ekkLanguage.orderList.notFound}
															</p>
														</div>
													</div>
												</IonCol>
											</>
										)}
									</div>
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		);
	} else if (data?.cardType === "procurementCard") {
		return (
			<div className='flex-1 flex'>
				{data.loading ? (
					<div className='flex items-center'>
						<h3
							className='font-bold text-16'
							style={{ color: "#555151" }}>
							Thinking
						</h3>
						<div className='loader'></div>
					</div>
				) : (
					<div
						className='font-semibold text-15 bg-white text-black-700 p-3 mb-[5px] md:min-w-[450px]'
						style={{
							borderRadius: "0px 15px 15px 15px",
							boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
						}}>
						<h1 className='text-left font-bold text-16 mb-5'>
							Procurement
						</h1>
						{/* <h4 className="font-bold text-16">Supplier list</h4> */}
						<span className='block'>
							{/* {data?.supplier?.map((line, index) => (
									<p>{index + 1} )  {line?.business_name}</p>
								))} */}
							Your procurement request based on the CAD Design file is
							created.
						</span>
						<div className='text-right'>
							<Link
								to={`/procurements/admin/${data?.produrmenId}`}
								className='text-12 font-semibold underline text-delivered '>
								See Details
							</Link>
						</div>
					</div>
				)}
			</div>
		);
	}
}
