import React from "react";
import SingleDepartment from "./SingleDepartment";
import { useSelector } from "react-redux";

export default function DepartmentList({ departmentList }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div>
			{departmentList.length > 0 ? (
				departmentList.map((el, i) => {
					return <SingleDepartment item={el} key={i} />;
				})
			) : (
				<>
					<div
						className='text-center flex items-center justify-center'
						style={{ height: "calc(100vh - 420px)" }}>
						<div>
							<img
								className='m-auto mb-[10px] mt-5'
								src='/assets/images/not-found-file.png'
								alt='emptyCard'
							/>
							<p className='text-16 font-normal text-222222 mb-[35px]'>
								{ekkLanguage.orderList.notFound}
							</p>
						</div>
					</div>
				</>
			)}
		</div>
	);
}
