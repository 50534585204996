import {
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
} from "@ionic/react";
import BrandList from "components/mobile-components/BrandList";
import BrandBottomSheet from "components/mobile-components/BrandList/BrandBottomSheet";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Brand from "services/Brand";

export default function MyBrandList() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [brandList, setBrandList] = useState([]);
	const [myBrand, setMyBrand] = useState(true);
	const [pageCount, setPageCount] = useState(2);
	const [searchQ, setSearchQ] = useState("");
	const [loading, setLoading] = useState(true);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);

	const getMyBrandList = async () => {
		setReachedEnd(false);
		setHasMoreData(true);
		setPageCount(2);
		if (searchQ) {
			let response = await Brand.getMyBrandListSearch(searchQ);
			if (response.status === 200) {
				setBrandList(response.results);
				setLoading(false);
			} else {
				setLoading(false);
			}
		} else {
			let response = await Brand.getMyBrandList();
			if (response.status === 200) {
				setBrandList(response.results);
				setLoading(false);
			} else {
				setLoading(false);
			}
		}
	};
	const getAllBrandList = async () => {
		setReachedEnd(false);
		setHasMoreData(true);
		setPageCount(2);
		if (searchQ) {
			let response = await Brand.getAllBrandListSearch(searchQ);
			if (response.status === 200) {
				setBrandList(response.results);
			}
		} else {
			let response = await Brand.getAllBrandList();
			if (response.status === 200) {
				setBrandList(response.results);
			}
		}
	};

	useEffect(() => {
		getMyBrandList();
	}, []);

	let lodeMoreData = async () => {
		if (hasMoreData) {
			if (searchQ) {
				if (myBrand) {
					const response = await Brand.getMyBrandListSearch(
						searchQ,
						pageCount
					);
					if (response.status === 200) {
						setPageCount((prev) => prev + 1);
						setBrandList([...brandList, ...response.results]);
					} else {
						setHasMoreData(false);
					}
				} else {
					const response = await Brand.getAllBrandListSearch(
						searchQ,
						pageCount
					);
					if (response.status === 200) {
						setPageCount((prev) => prev + 1);
						setBrandList([...brandList, ...response.results]);
					} else {
						setHasMoreData(false);
					}
				}
			} else {
				if (myBrand) {
					const response = await Brand.getMyBrandList(pageCount);
					if (response.status === 200) {
						setPageCount((prev) => prev + 1);
						setBrandList([...brandList, ...response.results]);
					} else {
						setHasMoreData(false);
					}
				} else {
					const response = await Brand.getAllBrandList(pageCount);
					if (response.status === 200) {
						setPageCount((prev) => prev + 1);
						setBrandList([...brandList, ...response.results]);
					} else {
						setHasMoreData(false);
					}
				}
			}
		}
	};

	let singleBrandSearch = async () => {
		if (myBrand) {
			let response = await Brand.getMyBrandListSearch(searchQ);
			if (response.status === 200) {
				setBrandList(response.results);
			}
		} else {
			let response = await Brand.getAllBrandListSearch(searchQ);
			if (response.status === 200) {
				setBrandList(response.results);
			}
		}
	};

	useEffect(() => {
		if (searchQ === "") {
			if (myBrand) {
				getMyBrandList();
			} else {
				getAllBrandList();
			}
		}
	}, [searchQ]);

	return (
		<div>
			<section className=''>
				<BrandList
					ekkLanguage={ekkLanguage}
					brandList={brandList}
					setMyBrand={setMyBrand}
					getAllBrandList={getAllBrandList}
					getMyBrandList={getMyBrandList}
					searchQ={searchQ}
					setSearchQ={setSearchQ}
					singleBrandSearch={singleBrandSearch}
					setHasMoreData={setHasMoreData}
					setReachedEnd={setReachedEnd}
					loading={loading}
				/>
			</section>
			<div>
				<IonInfiniteScroll
					onIonInfinite={(ev) => {
						if (reachedEnd) {
							ev.target.complete();
							return;
						}
						lodeMoreData();
						setTimeout(() => {
							ev.target.complete();
							if (!hasMoreData) {
								setReachedEnd(true);
							}
						}, 1000);
					}}>
					<IonInfiniteScrollContent
						loadingText={ekkLanguage.orderList.loader}
						loadingSpinner={
							hasMoreData ? "bubbles" : null
						}></IonInfiniteScrollContent>
				</IonInfiniteScroll>
			</div>
			{/* <BrandBottomSheet /> */}
		</div>
	);
}
