import {
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
	IonLoading,
	IonSplitPane,
} from "@ionic/react";
import BottomMenu from "components/global-components/common/BottomMenu";
import BazAiBottomChatArea from "components/mobile-components/BazAI/BazAiBottomChatArea";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import BazAiNav from "components/mobile-components/Common/BazAiNav";
import BazAIContactCard from "components/mobile-components/ProcurementOrderDetails.js/BazAIContactCard";
import BazAIStatusCard from "components/mobile-components/ProcurementOrderDetails.js/BazAIStatusCard";
import ProcurementInfoCard from "components/mobile-components/ProcurementOrderDetails.js/ProcurementInfoCard";
import WOCCard from "components/mobile-components/ProcurementOrderDetails.js/WOCCard";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import Procurement from "services/Procurement";
import { useDropzone } from "react-dropzone";
import DocumentsCard from "components/mobile-components/ProcurementOrderDetails.js/DocumentsCard";
import BidsCard from "components/mobile-components/ProcurementOrderDetails.js/BidsCard";
import EvaluationFeedbackModal from "components/mobile-components/ProcurementOrderDetails.js/EvaluationFeedbackModal";
import AwardCard from "components/mobile-components/ProcurementOrderDetails.js/AwardCard";
import ActionButtonsCard from "components/mobile-components/ProcurementOrderDetails.js/ActionButtonsCard";
import BidFeedbackModal from "components/mobile-components/ProcurementOrderDetails.js/BidsFeedBackModal";
import {
	setBidList,
	setProcurementDetails,
} from "redux/container/procurementSlice";
import debounce from "lodash.debounce";

export default function ProcurementListPage() {
	const { procid, type } = useParams();
	const messageData = useSelector((state) => state?.bazAi?.data);
	const [procurements, setProcurements] = useState([]);
	const [loading, setLoading] = useState(true);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);
	const [pageCount, setPageCount] = useState(2);
	const [selectedProcurement, setSelectedProcurement] = useState({});
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [files, setFiles] = useState([]);
	const [ripple, setRipple] = useState(false);
	const dispatch = useDispatch();

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [modalContent, setModalContent] = useState(null);
	const [updatePage, setUpdatePage] = useState(false);
	const procurementDetails = useSelector((state) => state.procurement.details);
	const bidList = useSelector((state) => state.procurement.bids);
	const [hasJustification, setHasJustification] = useState(true);

	// Handle decision click function
	const handleDecisionClick = (decisionType) => {
		setModalContent(decisionType);
		setModalIsOpen(
			decisionType === "Accept" || decisionType === "Reject" ? true : false
		);
	};

	// Handle modal close function
	const handleModalClose = () => {
		setModalIsOpen(false);
		setModalContent(null);
	};

	const getProcurementListDetails = async (id) => {
		const response = await Procurement.procurementDetails(Number(id));
		if (response.status === 200) {
			dispatch(setProcurementDetails(response?.results));
			setHasJustification(
				response?.results?.waiver_of_competition ? true : false
			);
			let res = await Procurement.bidList(Number(id));
			if (res.status === 200 || res.status === 404) {
				dispatch(setBidList(res?.results));
				setLoading(false);
				setUpdatePage(false);
			}
		} else {
			console.log("Error fetching procurement details", response);
		}
	};

	// useEffect(() => {
	// 	getProcurementList();
	// }, []);

	useEffect(() => {
		if (updatePage) {
			setLoading(true);
			getProcurements();
			// getProcurementListDetails(procid ? procid : selectedProcurement?.id);
		}
	}, [updatePage]);

	const { getRootProps, getInputProps } = useDropzone({
		// accept: "image/jpeg, image/png, image/jpg",
		onDrop: (acceptedFiles) => {
			if (
				acceptedFiles[0].type === "image/jpeg" ||
				acceptedFiles[0].type === "image/png" ||
				acceptedFiles[0].type === "image/jpeg"
			) {
				setFiles(
					acceptedFiles.map((file) =>
						Object.assign(file, {
							preview: URL.createObjectURL(file),
						})
					)
				);
			}
		},
	});

	const getProcurements = async (page) => {
		setLoading(true);
		const response = await Procurement.getProcurementList(page, 18);
		if (response?.success) {
			let matchProcData = response.results?.find(
				(el) => el?.id === Number(procid)
			);
			setProcurements(response?.results);
			setSelectedProcurement(
				matchProcData?.id ? matchProcData : response.results[0]
			);

			setSelectedIndex(
				matchProcData?.id
					? response?.results.findIndex(
							(el) => el?.id === matchProcData?.id
					  )
					: 0
			);
			getProcurementListDetails(procid ? procid : response?.results[0]?.id);
		}
		setLoading(false);
	};

	useEffect(() => {
		getProcurements();
	}, []);

	const loadMoreData = async () => {
		if (hasMoreData && procurements?.length > 0) {
			let response = await Procurement.getProcurementList(pageCount);
			if (response.status === 200) {
				setPageCount((prev) => prev + 1);
				setProcurements([...procurements, ...response.results]);
				if (!response.results.length) {
					setHasMoreData(false);
				}
			} else {
				setHasMoreData(false);
			}
		}
	};

	const handleProcurementClick = (procurement, index) => {
		setSelectedProcurement(procurement);
		setSelectedIndex(index);
		getProcurementListDetails(procurement?.id);
	};
	const tableRef = useRef(null);
	const previousScrollPosition = useRef(0);
	let currentPage = 1;
	const handleScroll = debounce(async () => {
		const container = tableRef.current;
		if (!container || loading) return;

		const { scrollTop, scrollHeight, clientHeight } = container;

		// Determine scroll direction
		const isScrollingDown = scrollTop > previousScrollPosition.current;
		previousScrollPosition.current = scrollTop;

		if (isScrollingDown && scrollTop + clientHeight >= scrollHeight - 20) {
			try {
				currentPage += 1;

				const response = await Procurement.getProcurementList(
					currentPage,
					18
				);
				if (response?.success) {
					setProcurements((prevOrders) => [
						...prevOrders,
						...response.results,
					]);
				}
			} catch (error) {
				console.error("Failed to fetch orders:", error);
			} finally {
				setLoading(false);
			}
		}
	}, 300);

	// Attach scroll listener
	useEffect(() => {
		const container = tableRef.current;
		if (container) {
			container.addEventListener("scroll", handleScroll);
			return () => container.removeEventListener("scroll", handleScroll);
		}
	}, [loading]);

	return (
		<IonPage>
			<IonContent>
				<div className='px-8 pb-5 mx-auto'>
					{/* <BackButtonTitle title="Procurements" /> */}
					<BazAiNav title='Procurements' />

					{loading ? (
						<IonLoading
							isOpen={loading}
							message={"Please wait..."}
							spinner='circles'
						/>
					) : procurements?.length === 0 ? (
						<div className='h-screen flex justify-center items-center text-gray-500 font-bold text-2xl'>
							<div
								className='text-center flex items-center justify-center'
								style={{ height: "calc(100vh - 420px)" }}>
								<div>
									<img
										className='m-auto mb-[10px] mt-5'
										src='/assets/images/not-found-file.png'
										alt='emptyCard'
									/>
									<p className='text-16 font-normal text-222222 mb-[35px]'>
										{ekkLanguage.orderList.notFound}
									</p>
								</div>
							</div>
						</div>
					) : (
						<div className='w-full flex lg:flex-row flex-col'>
							<div className='lg:w-1/2 w-full py-2.5'>
								{/* Left Pane (Table) */}
								<div
									className='bg-[#ffffff] rounded-[10px] mt-20'
									style={{
										boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
									}}>
									{/* <h3 className='text-222222 font-bold text-24 py-2 px-4'>
										Procurements
									</h3> */}
									<div
										className={`border border-gray-300 rounded-lg p-4 ${
											procurements?.length > 15 && "h-[780px]"
										} overflow-y-auto`}
										ref={tableRef}>
										<div className=''>
											<table className='min-w-full divide-y divide-gray-200'>
												<thead>
													<tr className='bg-gray-500 text-white'>
														<th className='text-left px-4 py-2'>
															S/N
														</th>
														<th className='text-left px-4 py-2'>
															Procurement Id
														</th>
														{/* <th className="text-left px-4 py-2">Start Date</th> */}
														{/* <th className="text-left px-4 py-2">
                              Procurement Type
                            </th> */}
														<th className='text-left px-4 py-2'>
															Created By
														</th>
														<th className='text-left px-4 py-2'>
															Status
														</th>
													</tr>
												</thead>
												<tbody>
													{procurements?.map(
														(procurement, index) => (
															<tr
																key={procurement?.id}
																className={`h-12 cursor-pointer transition-colors duration-200 
                              ${
											selectedIndex === index
												? "bg-gray-400"
												: index % 2 === 0
												? "bg-white"
												: "bg-gray-50"
										}`}
																onClick={() => {
																	setSelectedIndex(index);
																	handleProcurementClick(
																		procurement,
																		index
																	);
																}}>
																<td className='text-black px-4 py-2'>
																	{index + 1}
																</td>
																<td className='text-black px-4 py-2 text-center'>
																	{procurement?.id}
																</td>
																{/* <td className="text-black px-4 py-2">
                                {moment(procurement?.start_date).format(
                                  "DD-MM-YYYY"
                                )}
                              </td> */}
																{/* <td className="text-black px-4 py-2 text-center">
                                {procurement?.proc_type}
                              </td> */}
																<td className='text-black px-4 py-2'>
																	{
																		procurement
																			?.created_by_businesses_emp_name[0]
																	}
																</td>
																<td className='px-4 py-2'>
																	<span
																		className={`${
																			procurement?.status ===
																			"Completed"
																				? "bg-green-100 text-green-800"
																				: procurement?.status ===
																				  "Pending"
																				? "bg-yellow-100 text-yellow-800"
																				: procurement?.status ===
																				  "Cancelled"
																				? "bg-red-100 text-red-800"
																				: "bg-gray-100 text-gray-800"
																		} rounded-full px-3 py-1 text-sm`}>
																		{procurement?.status
																			.charAt(0)
																			.toUpperCase() +
																			procurement?.status.slice(
																				1
																			)}
																	</span>
																</td>
															</tr>
														)
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div>

								{/* Right Pane (Details) */}
							</div>
							<div
								id='main-content'
								className='rounded-lg lg:w-1/2 w-full  mt-20'>
								{selectedProcurement && (
									<div className='py-2 px-4 flex flex-col justify-center'>
										{/* Display details of selectedProcurement */}
										<BazAIStatusCard
											procurementDetails={selectedProcurement}
										/>
										<ProcurementInfoCard
											procurementDetails={selectedProcurement}
										/>
										{/* <BazAIContactCard
											procurementDetails={procurementDetails}
										/> */}
										<WOCCard
											procurementDetails={procurementDetails}
											modalIsOpen={modalIsOpen}
											modalContent={modalContent}
											handleModalClose={handleModalClose}
											setUpdatePage={setUpdatePage}
										/>
										<DocumentsCard />
										<BidsCard bidList={bidList} />

										{/* {((procurementDetails?.status === "Submission" &&
											!hasJustification) ||
											procurementDetails?.status ===
												"Divisional Approval" ||
											procurementDetails?.status === "Published" ||
											procurementDetails?.status === "Bidding" ||
											procurementDetails?.status === "Evaluation" ||
											procurementDetails?.status === "Negotiation" ||
											procurementDetails?.status === "Awarded") && (
											<DocumentsCard />
										)}

										{(procurementDetails?.status === "Published" ||
											procurementDetails?.status === "Bidding" ||
											procurementDetails?.status === "Evaluation" ||
											procurementDetails?.status === "Negotiation" ||
											procurementDetails?.status === "Awarded") && (
											<>
												<BidsCard bidList={bidList} />
											</>
										)} */}
										{(procurementDetails?.status === "Bidding" ||
											procurementDetails?.status === "Evaluation" ||
											procurementDetails?.status ===
												"Negotiation") && (
											<div className='grid lg:grid-cols-3 grid-cols-1 gap-5 w-full px-4'>
												<EvaluationFeedbackModal
													bidList={bidList}
													setUpdatePage={setUpdatePage}
												/>
												<BidFeedbackModal
													bidList={bidList}
													procurementDetails={procurementDetails}
													setUpdatePage={setUpdatePage}
												/>
												<AwardCard
													bidList={bidList}
													from={type}
													setUpdatePage={setUpdatePage}
													updatePage={updatePage}
												/>
											</div>
										)}
										<ActionButtonsCard
											status={procurementDetails?.status}
											onDecisionClick={handleDecisionClick}
											setUpdatePage={setUpdatePage}
											hasJustification={hasJustification}
											role={type}
										/>
									</div>
								)}
							</div>
							<BottomMenu />
							<div className='pt-5'>
								{/* <IonInfiniteScroll
                  onIonInfinite={(ev) => {
                    if (reachedEnd) {
                      ev.target.complete();
                      return;
                    }
                    loadMoreData();
                    setTimeout(() => {
                      ev.target.complete();
                      if (!hasMoreData) {
                        setReachedEnd(true);
                      }
                    }, 1000);
                  }}
                >
                  <IonInfiniteScrollContent
                    loadingText={ekkLanguage.orderList.loader}
                    loadingSpinner={hasMoreData ? "bubbles" : null}
                  ></IonInfiniteScrollContent>
                </IonInfiniteScroll> */}
							</div>
						</div>
					)}
					{/* <div className="container">
            <BazAiBottomChatArea
              // textareaRef={textareaRef}
              messageData={messageData}
              // handleKeyDown={handleKeyDown}
              // height={height}
              // handleInputChange={handleInputChange}
              // setComment={setComment}
              // comment={comment}
              // handleAssistant={handleAssistant}
              // timer={timer}
              // formatTime={formatTime}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              // handleTouchStart={handleTouchStart}
              // handleTouchEnd={handleTouchEnd}
              ripple={ripple}
            />
          </div> */}
				</div>
				<BottomMenu />
			</IonContent>
		</IonPage>
	);
}
