import {
	IonButton,
	IonCol,
	IonGrid,
	IonInput,
	IonItem,
	IonRow,
	IonLoading,
	useIonAlert,
	IonSpinner,
} from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import LocationPin from "assets/images/icon/svg/LocationPin";
import {
	getCreateUserBusiness,
	getLocalUser,
	getUser,
	setCreateUserBusiness,
	setLocalUser,
	setUserBusiness,
} from "config/user";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Auth from "services/Auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateToken } from "config/SuperFetch";
import { useDispatch, useSelector } from "react-redux";
import { postalCodeInfo } from "data/postalCode";
import Business from "services/Business";
import { Input } from "components/global-components/common/Input";
import Select from "react-select";
import { State } from "country-state-city";
import { ALLCOUNTRYINFO } from "data/country";
import Autocomplete from "react-google-autocomplete";
import Search from "../Common/Search";
import { baseUrl } from "config/apiUrl";
import { eraseMapLocation, setMapLocation } from "redux/container/userSlice";
import { setKYC } from "redux/container/businessSettingsSlice";

export default function BusinessLocation2() {
	const location = useSelector((state) => state.user.mapLocation);
	const [address, setAddress] = useState(location?.address ?? "");
	const [city, setCity] = useState(location?.city ?? "");
	const [state, setState] = useState(location?.state ?? "");
	const [postalCode, setPostalCode] = useState(location?.postalCode ?? "");
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const businessState = useSelector((state) => state.user.newBusinessState);
	const [country, setCountry] = useState(location?.country ?? "");
	const dispatch = useDispatch();
	// let postalCode = postalCodeInfo.find((el) =>
	// 	String(location.area).includes(el.thana)
	// );

	// const [postCode, setPostCode] = useState(location?.postalCode ?? "");
	const [email, setEmail] = useState(getCreateUserBusiness().email);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [cityError, setCityError] = useState();
	const [postCodeError, setPostCodeError] = useState();
	const [emailError, setEmailError] = useState("");
	const [businessLoading, setBusinessLoading] = useState(false);
	let autocompleteRef = useRef(null);

	const navigate = useNavigate();
	const data = getCreateUserBusiness();
	const user = getLocalUser();
	const loggedInUser = getUser();

	let countryCode =
		ALLCOUNTRYINFO[
			country
				? country
				: user?.user?.country
				? user?.user?.country
				: loggedInUser?.country
		]?.countryShortName;
	let stateOptions = State.getStatesOfCountry(countryCode)?.map(
		(el) => el && { ...el, label: el?.name, value: el?.name }
	);

	async function businessDataSave() {
		if (address) {
			setError("");
			if (city) {
				setCityError("");
				if (postalCode) {
					setPostCodeError("");
					if (email) {
						setEmailError("");
						try {
							// console.log(city, postCode, address, email);
							setLoading(true);
							const response = await Business.createUserBusiness({
								...data,
								business_group: "Seller",
								app_origin: "baz-ai",
							});
							if (response.status === 201) {
								let userData = {
									associated_businesses: [{ ...response.results }],
									user: response?.user,
									business_group: "Seller",
								};
								setUserBusiness({ ...response.results });
								dispatch(
									setKYC({
										business: response.results.id,
										kycUpdate: false,
									})
								);

								setLoading(false);
								toast.success(
									ekkLanguage.businessLocation.successLabel
								);
								localStorage.setItem(
									"setUser",
									JSON.stringify(userData)
								);
								updateToken(response);
								dispatch(eraseMapLocation());
								const res = await Auth.businessSelect2({
									...response.results,
								});
								if (res.status === 200) {
									setUserBusiness(res?.business_info);
									updateToken(res);
								}
								navigate("/business-success");
							} else {
								setLoading(false);
							}
						} catch (error) {
							console.log(error);
						}
					} else {
						setEmailError(
							ekkLanguage.businessLocation.validationEmailLabel
						);
					}
				} else {
					setPostCodeError(
						ekkLanguage.businessLocation.validationPostCodeLabel
					);
				}
			} else {
				setCityError(ekkLanguage.businessLocation.validationCityLabel);
			}
		} else {
			setError(ekkLanguage.businessLocation.validationLabel);
		}
	}

	useEffect(() => {
		if (address) {
			setError("");
		}
	}, [address]);

	const selectStateChanger = (selectedOption) => {
		const business = getCreateUserBusiness();
		business.state = selectedOption?.value;
		setState(selectedOption?.value);
		setCreateUserBusiness(business);
	};

	// useEffect(() => {
	// 	if (location?.address) {
	// 		setError("");
	// 		const business = getCreateUserBusiness();
	// 		business.address_line = location?.address;
	// 		business.postal_code = location?.postal_code;
	// 		setCreateUserBusiness(business);
	// 	}
	// }, []);

	const onPlaceSelected = (place) => {
		const address = place.formatted_address,
			addressArray = place.address_components,
			postalCode = getPostalCode(addressArray),
			city = getCity(addressArray),
			area = getArea(addressArray),
			state = getState(addressArray),
			country = getCountry(addressArray),
			latValue = place.geometry.location.lat(),
			lngValue = place.geometry.location.lng();
		setAddress(address ?? "");
		setCity(city ?? "");
		setPostalCode(postalCode ?? "");
		setState(state ?? "");
		setCountry(country ?? "");
		const business = getCreateUserBusiness();
		business.address_line = address;
		business.postal_code = postalCode;
		business.city = city;
		business.state = state;
		business.country = country;
		business.long_lat = {
			type: "Point",
			coordinates: [lngValue, latValue],
		};

		setCreateUserBusiness(business);
		let locationBuilder = {
			address,
			city,
			area,
			state,
			postalCode,
			lat: latValue,
			lng: lngValue,
		};
		dispatch(setMapLocation(locationBuilder));

		// const business = getCreateUserBusiness();
		// business.address_line = address;
		// business.postal_code = postalCode;
		// setCreateUserBusiness(business);
	};

	//get city
	const getCity = (addressArray) => {
		let city = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (
				(addressArray[i].types[0] &&
					"sublocality_level_1" === addressArray[i].types[0]) ||
				(addressArray[i].types[0] &&
					"locality" === addressArray[i].types[0])
			) {
				city = addressArray[i].long_name;
				return city;
			}
		}
	};

	//get area
	const getArea = (addressArray) => {
		let area = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0]) {
				for (let j = 0; j < addressArray[i].types.length; j++) {
					if (
						"sublocality_level_1" === addressArray[i].types[j] ||
						"locality" === addressArray[i].types[j]
					) {
						area = addressArray[i].long_name;
						return area;
					}
				}
			}
		}
	};

	//get state
	const getState = (addressArray) => {
		let state = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (
				addressArray[i].types[0] &&
				"administrative_area_level_1" === addressArray[i].types[0]
			) {
				state = addressArray[i].long_name;
				return state;
			}
		}
	};
	//get country
	const getCountry = (addressArray) => {
		let country = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (
				addressArray[i].types[0] &&
				"country" === addressArray[i].types[0]
			) {
				country = addressArray[i].long_name;
				return country;
			}
		}
	};

	//get postal code
	const getPostalCode = (addressArray) => {
		let postalCode = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types.includes("postal_code")) {
				postalCode = addressArray[i].long_name;
				break;
			}
		}
		return postalCode;
	};

	return (
		<div className='business-location pt-14'>
			{loading ? (
				<IonLoading
					isOpen={loading}
					// onDidDismiss={() => setShowLoading(false)}
					message={ekkLanguage.businessLocation.loader}
				/>
			) : (
				""
			)}
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<div className='pt-2 text-center'>
							<h5 className='text-black-1000 text-16 font-medium pb-3'>
								{ekkLanguage.businessLocation.paragraph}
							</h5>
							<div className='text-center'>
								<span className='business-loation-pin'>
									<LocationPin />
								</span>
							</div>
							<div className='text-left'>
								<IonRow className='pb-5'>
									<IonCol size='12'>
										<div className=' uppercase tracking-wide text-grey-darker text-sm font-semibold mb-2'>
											{ekkLanguage.businessLocation.address}
										</div>
										<div className=''>
											<Autocomplete
												apiKey={baseUrl.mapKey}
												ref={autocompleteRef}
												style={{
													width: "100%",
													height: "38px",
													paddingLeft: "16px",
													marginTop: "5px",
													marginBottom: "0px",
													borderRadius: "4px",
													border: "1px solid #00000020",
												}}
												onPlaceSelected={onPlaceSelected}
												options={{
													types: ["geocode"],
												}}
												placeholder='Search location'
												defaultValue={`${location.address ?? ""}`}
											/>
										</div>
									</IonCol>
								</IonRow>
								<IonRow className='pb-5'>
									<IonCol size='6'>
										<label className=' uppercase tracking-wide text-grey-darker text-sm font-semibold mb-2'>
											State
										</label>
										<div className={"w-full mt-2"}>
											<Select
												isSearchable={false}
												value={stateOptions?.map((el) =>
													el?.name === state ? el : null
												)}
												placeholder='Select state'
												options={stateOptions}
												onChange={selectStateChanger}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														// width: "100%",
														height: 38,
														// border: "none",
														outLine: "none",
														fontWeight: 600,
														fontSize: 12,
														borderRadius: 4,
														borderColor: "#00000030",
													}),
												}}
												theme={(theme) => ({
													...theme,
													borderRadius: 4,
													colors: {
														...theme.colors,
														primary25: "rgb(204, 255, 214)",
														primary: "#00000030",
													},
												})}
											/>
										</div>
									</IonCol>
									<IonCol size='6'>
										<div>
											<Input
												value={city}
												onChange={(event) => {
													setCity(event.target.value);
													const business = getCreateUserBusiness();
													business.city = event.target.value;
													setCreateUserBusiness(business);
												}}
												type='text'
												label={ekkLanguage.businessLocation.city}
												placeholder={
													ekkLanguage.businessLocation
														.cityPlaceholder
												}
												required
											/>
											{cityError && (
												<div className='text-primary mt-1 text-right'>
													{cityError}
												</div>
											)}
										</div>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol size='6'>
										<div>
											<Input
												value={postalCode}
												onChange={(event) => {
													setPostalCode(event.target.value);
													const business = getCreateUserBusiness();
													business.postal_code =
														event.target.value;
													setCreateUserBusiness(business);
												}}
												type='string'
												label={
													ekkLanguage.businessLocation.postalCode
												}
												placeholder={
													ekkLanguage.businessLocation
														.postalCodePlaceholder
												}
												required
											/>
											{postCodeError && (
												<div className='text-primary mt-1 text-right'>
													{postCodeError}
												</div>
											)}
										</div>
									</IonCol>
									<IonCol size='6'>
										<div>
											<Input
												onChange={(event) => {
													setEmail(event.target.value);
													const business = getCreateUserBusiness();
													business.email = event.target.value;
													setCreateUserBusiness(business);
												}}
												type='email'
												label={ekkLanguage.businessLocation.email}
												placeholder={
													ekkLanguage.businessLocation
														.emailPlaceholder
												}
												required
												errorMessage={emailError}
											/>
										</div>
									</IonCol>
								</IonRow>
							</div>
							<div className='pt-24'>
								<IonButton
									color='success'
									className='font-bold h-10 w-full'
									onClick={() => {
										!businessLoading && businessDataSave();
									}}>
									<span className='text-white'>
										{businessLoading ? (
											<IonSpinner
												color='light'
												name='crescent'></IonSpinner>
										) : (
											ekkLanguage.businessLocation.btnLabel
										)}
									</span>
								</IonButton>
							</div>
						</div>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
}
