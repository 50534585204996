import React from "react";
import moment from "moment";
import BazAICard from "./BazAICard";

function ProcurementInfoCard({ procurementDetails }) {
	function isJsonString(str) {
		try {
			JSON.parse(str);
			return true;
		} catch (e) {
			return false;
		}
	}

	let procDis = isJsonString(procurementDetails?.description)
		? JSON.parse(procurementDetails?.description)
		: procurementDetails?.description;

	const formatDescription = (description) => {
		let descriptionItems = [];
		let keyRequirements = [];

		try {
			const jsonDescription = JSON.parse(description);
			descriptionItems = Object.entries(jsonDescription)
				.filter(([key]) => key !== "Key Requirements")
				.map(([key, value]) => {
					if (Array.isArray(value)) {
						return `${key}: ${value.join(", ")}`;
					} else {
						return `${key}: ${value}`;
					}
				});
			// Extract key requirements if present
			if (jsonDescription["Key Requirements"]) {
				keyRequirements = jsonDescription["Key Requirements"];
			}
		} catch (error) {
			// Handle case where description is not JSON
			descriptionItems = [description];
		}

		return { descriptionItems, keyRequirements };
	};

	const { descriptionItems, keyRequirements: extractedKeyRequirements } =
		formatDescription(procurementDetails?.description);

	return (
		<div className='px-4 pb-5'>
			<BazAICard title={"Procurement Information"}>
				{/* Header Info Section */}
				<div className='border border-gray-300 rounded-lg p-4'>
					<table className='w-full border-collapse'>
						<tbody>
							{/* Row 1 */}
							<tr className='bg-blue-50 h-10'>
								<td className='text-blue-900 text-sm font-medium py-2 px-4'>
									Calling Entity
								</td>
								<td className='text-blue-900 text-sm font-medium py-2 px-4 text-right'>
									{procurementDetails?.created_by_businesses_name[0]}
								</td>
							</tr>
							{/* Row 2 */}
							<tr className='bg-white h-10'>
								<td className='text-blue-900 text-sm font-medium py-2 px-4'>
									Reference No.
								</td>
								<td className='text-blue-900 text-sm font-medium py-2 px-4 text-right'>
									{procurementDetails?.ref}
								</td>
							</tr>
							{/* Row 3 */}
							<tr className='bg-blue-50 h-10'>
								<td className='text-blue-900 text-sm font-medium py-2 px-4'>
									Document Type
								</td>
								<td className='text-blue-900 text-sm font-medium py-2 px-4 text-right'>
									{procurementDetails?.proc_type}
								</td>
							</tr>
							{/* Row 4 */}
							<tr className='bg-white h-10'>
								<td className='text-blue-900 text-sm font-medium py-2 px-4'>
									Starting Date/Time
								</td>
								<td className='text-blue-900 text-sm font-medium py-2 px-4 text-right'>
									{moment().format("DD-MM-YYYY / HH:mm")}
								</td>
							</tr>
							{/* Row 5 */}
							{/* <tr className='bg-blue-50 h-10'>
								<td className='text-blue-900 text-sm font-medium py-2 px-4'>
									Payment Term
								</td>
								<td className='text-blue-900 text-sm font-medium py-2 px-4 text-right'>
									45 Days
								</td>
							</tr> */}
							{/* Row 6 */}
							<tr className='bg-white h-10'>
								<td className='text-blue-900 text-sm font-medium py-2 px-4'>
									Closing Date/Time
								</td>
								<td className='text-blue-900 text-sm font-medium py-2 px-4 text-right'>
									{moment()
										.add(30, "days")
										.format("DD-MM-YYYY / HH:mm")}
								</td>
							</tr>
							{/* Row 7 */}
							{/* <tr className='bg-blue-50 h-10'>
								<td className='text-blue-900 text-sm font-medium py-2 px-4'>
									Currency
								</td>
								<td className='text-blue-900 text-sm font-medium py-2 px-4 text-right'>
									SGD
								</td>
							</tr> */}
							{/* Row 8 */}
							{/* <tr className='bg-white h-10'>
								<td className='text-blue-900 text-sm font-medium py-2 px-4'>
									Delivery Term
								</td>
								<td className='text-blue-900 text-sm font-medium py-2 px-4 text-right'>
									DDP-Delivered Duty Paid - Named Place of Destination
								</td>
							</tr> */}
							{/* Row 9 */}
							{/* <tr className='bg-blue-50 h-10'>
								<td className='text-blue-900 text-sm font-medium py-2 px-4'>
									Validity Period of Quotation
								</td>
								<td className='text-blue-900 text-sm font-medium py-2 px-4 text-right'>
									90 Days
								</td>
							</tr> */}
							{/* Row 10 */}
							{/* <tr className='bg-white h-10'>
								<td className='text-blue-900 text-sm font-medium py-2 px-4'>
									Product Category
								</td>
								<td className='text-blue-900 text-sm font-medium py-2 px-4 text-right'>
									Construction, Additions & Alterations works
								</td>
							</tr> */}
							{/* Row 11 */}
							{/* <tr className='bg-blue-50 h-10'>
								<td className='text-blue-900 text-sm font-medium py-2 px-4'>
									Any Personal Data Involved?
								</td>
								<td className='text-blue-900 text-sm font-medium py-2 px-4 text-right'>
									No
								</td>
							</tr> */}
							{/* Row 12 */}
							<tr className='bg-white h-10'>
								<td className='text-blue-900 text-sm font-medium py-2 px-4 td-description w-4/12'>
									Description
								</td>
								<td className='text-blue-900 text-sm font-medium py-2 px-4 text-right td-details w-8/12'>
									<div className=''>
										{isJsonString(procurementDetails?.description) ? (
											<div className=''>
												<table
													className='table-auto w-full bg-white'
													style={{
														boxShadow:
															"0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
													}}>
													<thead>
														<tr className='border h-[45px] text-center border-r-0 border-l-0 border-D9D9D9 px-4'>
															<th className='border p-1 font-bold text-16'>
																S/N
															</th>
															<th className='border p-1 font-bold text-16 text-left'>
																Component Name
															</th>
														</tr>
													</thead>
													<tbody>
														{procDis?.length
															? procDis?.map(
																	(component, index) => (
																		<tr
																			key={`${component?.name}-${index}`}
																			className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'>
																			<td className='border p-2 w-14 font-semibold text-14'>
																				{index + 1}
																			</td>

																			<td className='border p-2 w-14 font-semibold text-14 text-left'>
																				{component?.name}
																			</td>
																		</tr>
																	)
															  )
															: undefined}
													</tbody>
												</table>
											</div>
										) : (
											<>
												{descriptionItems.map((item, index) => (
													<p key={index} className='text-left'>
														{item}
													</p>
												))}
												{extractedKeyRequirements.length > 0 && (
													<>
														<h6 className='text-left font-bold mt-2'>
															Key Requirements:
														</h6>
														{extractedKeyRequirements.map(
															(req, index) => (
																<li
																	key={
																		index +
																		descriptionItems.length
																	}
																	className='text-left'>
																	{req}
																</li>
															)
														)}
													</>
												)}
											</>
										)}
										{/* <div className=''>
											<table
												className='table-auto w-full bg-white'
												style={{
													boxShadow:
														"0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
												}}>
												<thead>
													<tr className='border h-[45px] text-center border-r-0 border-l-0 border-D9D9D9 px-4'>
														<th className='border p-1 font-bold text-16'>
															S/N
														</th>
														<th className='border p-1 font-bold text-16 text-left'>
															Component Name
														</th>
													</tr>
												</thead>
												<tbody>
													{procDis?.length
														? procDis?.map((component, index) => (
																<tr
																	key={`${component?.name}-${index}`}
																	className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'>
																	<td className='border p-2 w-14 font-semibold text-14'>
																		{index + 1}
																	</td>

																	<td className='border p-2 w-14 font-semibold text-14 text-left'>
																		{component?.name}
																	</td>
																</tr>
														  ))
														: undefined}
												</tbody>
											</table>
										</div> */}
									</div>
								</td>
							</tr>
							{/* Row 13 */}
							{/* <tr className='bg-blue-50 h-10'>
								<td className='text-blue-900 text-sm font-medium py-2 px-4'>
									Vendor Eligibility
								</td>
								<td className='text-blue-900 text-sm font-medium py-2 px-4 text-right'></td>
							</tr> */}
							{/* Row 14 */}
							{/* <tr className='bg-white h-10'>
								<td className='text-blue-900 text-sm font-medium py-2 px-4'>
									Note of Supplier
								</td>
								<td className='text-blue-900 text-sm font-medium py-2 px-4 text-right'></td>
							</tr> */}
						</tbody>
					</table>
				</div>
			</BazAICard>
		</div>
	);
}

export default ProcurementInfoCard;
