import Search from "components/mobile-components/Common/Search";
import DepartmentList from "components/mobile-components/DepartmentList";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Brand from "services/Brand";
import Product from "services/Product";

export default function DepartmentListOnAssets() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [departmentList, setDepartmentList] = useState([]);
	const [pageCount, setPageCount] = useState(2);
	const [searchQ, setSearchQ] = useState("");

	let getDepartment = async () => {
		let response = await Product.getDepartment();
		if (response.status === 200) {
			setDepartmentList(response.results);
		}
	};
	useEffect(() => {
		getDepartment();
	}, []);

	let singleDepartmentSearch = async () => {
		let response = await Brand.getSingleDepartment(searchQ);
		if (response.status === 200) {
			setDepartmentList(response.results);
		}
	};

	useEffect(() => {
		if (searchQ === "") {
			getDepartment();
		}
	}, [searchQ]);

	return (
		<div>
			<section className=''>
				<div className='px-4 pb-5'>
					<Search
						placeholder={ekkLanguage.createProduct.searchDepartment}
						search={searchQ}
						setSearch={setSearchQ}
						singleSearch={singleDepartmentSearch}
					/>
				</div>
				<DepartmentList departmentList={departmentList} />
			</section>
		</div>
	);
}
