import React, { useState, useEffect } from "react";
import { AudioVisualizer } from "react-audio-visualize";
import PlayPauseBtn from "./PlayPauseBtn";

export default function AudioPlayer({ data, setAudioPlayer, audioPlayer }) {
	const [audioRef] = useState(() => React.createRef());
	const [isPlaying, setIsPlaying] = useState(false);
	const [blob, setBlob] = useState(null);

	useEffect(() => {
		// Convert base64 to blob
		if (data?.audioUrl) {
			fetch(data.audioUrl)
				.then((res) => res.blob())
				.then((blob) => {
					setBlob(blob);
				});
		}
	}, [data?.audioUrl]);

	const handlePlayPause = () => {
		if (isPlaying) {
			audioRef.current.pause();
		} else {
			audioRef.current.play();
		}
		setIsPlaying(!isPlaying);
	};

	return (
		<>
			<PlayPauseBtn
				audioRef={audioRef}
				handlePlayPause={handlePlayPause}
				audioPlayer={audioPlayer}
				isPlaying={isPlaying}
				setAudioPlayer={setAudioPlayer}
			/>
			{blob && (
				<AudioVisualizer
					blob={blob}
					width={200}
					height={52}
					barWidth={3}
					gap={1.5}
					barColor={"white"}
				/>
			)}

			<audio
				src={data?.audioUrl}
				ref={audioRef}
				onEnded={() => setIsPlaying(false)}
			/>
		</>
	);
}
