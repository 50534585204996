import Login from "pages/Auth/Login";
import Landing from "pages/Landing";
import BlogPostPage from "pages/Landing/BlogPostPage";
import SingleBlogPage from "pages/Landing/SingleBlogPage";
import BrandDetailsPage from "pages/MobilePage/BrandDetailsPage";
import BusinessLocationPage from "pages/MobilePage/BusinessLocationPage";
import BusinessLocationPickerPage from "pages/MobilePage/BusinessLocationPicker";
import BusinessNamePage from "pages/MobilePage/BusinessNamePage";
import BusinessProfilePage from "pages/MobilePage/BusinessProfilePage";
import BusinessTypePage from "pages/MobilePage/BusinessTypePage";
import ChangeLanguagePage from "pages/MobilePage/ChangeLanguagePage";
import ChangePasswordPage from "pages/MobilePage/ChangePasswordPage";
import ChangePasswordSuccessPage from "pages/MobilePage/ChangePasswordSuccessPage";
import CongratulationPage from "pages/MobilePage/CongratulationPage";
import ContactPage from "pages/MobilePage/ContactPage";
import CreateOrder from "pages/MobilePage/CreateOrder";
import EditBusinessPage from "pages/MobilePage/EditBusinessPage";
import EmployeesDetailsPage from "pages/MobilePage/EmployeesDetailsPage";
import EmployeesListPage from "pages/MobilePage/MyEmployeesListPage";
import Error from "pages/MobilePage/Error";
import Invoice from "pages/MobilePage/Invoice";
import LocationPage from "pages/MobilePage/LocationPage";
import Map from "pages/MobilePage/Map";
import MySupplierPage from "pages/MobilePage/MySupplierPage";
import MySuppliersDetailsPage from "pages/MobilePage/MySuppliersDetailsPage";
import OrderDetailsPage from "pages/MobilePage/OrderDetailsPage";
import OrderList from "pages/MobilePage/OrderList";
import PrivacyPolicy from "pages/MobilePage/PrivacyPolicy";
import ProductDetailsPage from "pages/MobilePage/ProductDetailsPage";
import ProfilePage from "pages/MobilePage/ProfilePage";
import ProfileVarifyOtpPage from "pages/MobilePage/ProfileVarifyOtpPage";
import ProfileVarifyPage from "pages/MobilePage/ProfileVarifyPage";
import ReceiptPage from "pages/MobilePage/ReceiptPage";
import RefundPolicy from "pages/MobilePage/RefundPolicy";
import SearchPage from "pages/MobilePage/SearchPage";
import SeeMoreProductPage from "pages/MobilePage/SeeMoreProductPage";
import SelectBusiness from "pages/MobilePage/SelectBusiness";
import SelectProductPage from "pages/MobilePage/SelectProductPage";
import SuppliersDetailsPage from "pages/MobilePage/SuppliersDetailsPage";
import TermsCondition from "pages/MobilePage/TermsCondition";
import VBNidPage from "pages/MobilePage/VBNid";
import VBPropertyPage from "pages/MobilePage/VBPropertyPage";
import VBRentalPage from "pages/MobilePage/VBRentalPage";
import VBTradLicensePage from "pages/MobilePage/VBTradLicensePage";
import { Route, Routes } from "react-router-dom";
import MyEmployeesListPage from "pages/MobilePage/MyEmployeesListPage";
import CreateEmployeePage from "pages/MobilePage/CreateEmployeePage";
import UpdateEmployeePage from "pages/MobilePage/UpdateEmployeesPage";
import ProductListPage from "pages/MobilePage/ProductListPage";
import BrandListPage from "pages/MobilePage/BrandListPage";
import CreateBrandPage from "pages/MobilePage/CreateBrandPage";
import CreateBrandSuccessPage from "pages/MobilePage/CreateBrandSuccessPage";
import CreateProductPage from "pages/MobilePage/CreateProductPage";
import UpdateBrandPage from "pages/MobilePage/UpdateBrandPage";
import UpdateBrandSuccessPage from "pages/MobilePage/UpdateBrandSuccessPage";
import MyBrandListPage from "pages/MobilePage/MyBrandListPage";
import DepartmentListPage from "pages/MobilePage/DepartmentListPage";
import UpdateProductPage from "pages/MobilePage/UpdateProductPage";
import CreatePackPage from "pages/MobilePage/CreatePackPage";
import CreateOfferPage from "pages/MobilePage/CreateOfferPage";
import PickLocationPage from "pages/MobilePage/PickLocationPage";
import CategoriesPage from "pages/MobilePage/CategoriesPage";
import SubCategoriesPage from "pages/MobilePage/SubCategoriesPage";
import UpdateOfferPage from "pages/MobilePage/UpdateOfferPage";
import PrivateOutlet from "./PrivateOutlet";
import CheckBusiness from "components/CheckBusiness";
import NIDVerificationPage from "pages/MobilePage/NIDVerificationPage";
import TradeLicensePage from "pages/MobilePage/TradeLicensePage";
import PropertyTypePage from "pages/MobilePage/PropertyTypePage";
import RentalInfoPage from "pages/MobilePage/RentalInfoPage";
import BusinessSuccess from "components/mobile-components/VerifyBusiness/BusinessSuccess";
import AllBrandListPage from "pages/MobilePage/AllBrandListPage";
import DashboardPage from "pages/MobilePage/DashboardPage";
import DashboardDetailsPage from "pages/MobilePage/DashboardDetailsPage";
import SupplierListPage from "pages/MobilePage/SupplierListPage";
import SupplierDetailPage from "pages/MobilePage/BrandSupplierDetailsPage";
import BrandProductDetailsPage from "pages/MobilePage/BrandProductDetailsPage";
import SelectOrderProductPage from "pages/MobilePage/SelectOrderProductPage";
import AccessRestrictPage from "pages/MobilePage/AccessRestrict";
import PaymentSettingsPage from "pages/MobilePage/PaymentSettingsPage";
import OrderProductSummaryPage from "pages/MobilePage/OrderProductSummaryPage";
import NotificationPage from "pages/MobilePage/NotificationPage";
import SupplierNotificationPage from "pages/MobilePage/SupplierNotificationPage";
import OrderProductSummaryDetailsPage from "pages/MobilePage/OrderProductSummaryDetailsPage";
import OrderDeliveryAmountSummaryPage from "pages/MobilePage/OrderDeliveryAmountSummaryPage";
import OrderDriverDeliverySummaryPage from "pages/MobilePage/OrderDriverDeliverySummaryPage";
import OrderDriverProductSummaryPage from "pages/MobilePage/OrderDriverProductSummaryPage";
import OrderDeliveryAmountDrilldownPage from "pages/MobilePage/OrderDeliveryAmountDrilldownPage";
import OrderDriverProductDrilldownPage from "pages/MobilePage/OrderDriverProductDrilldownPage";
import MyDriversListPage from "pages/MobilePage/MyDriverListPage";
import BuyerSummaryPage from "pages/MobilePage/BuyerSummaryPage";
import DelayInPaymentPage from "pages/MobilePage/DelayInPaymentPage";
import OtpSubmitPage from "pages/MobilePage/OtpSubmitPage";
import DelayInPaymentDrilldownPage from "pages/MobilePage/DelayInPaymentDrilldownPage";
import AddBankAccountPage from "pages/MobilePage/AddBankAccountPage";
import AutoLogoutPage from "pages/MobilePage/AutoLogoutPage";
import BuyerSummeryDrilldownPage from "pages/MobilePage/BuyerSummeryDrilldownPage";
import CustomerListPage from "pages/MobilePage/CustomerListPage";
import PayEkkbazPage from "pages/MobilePage/PayEkkbazPage";
import CustomerDetailsPage from "pages/MobilePage/CustomerDetailsPage";
import AddBankAccount from "components/mobile-components/AddBankAccount";
import BankListPage from "pages/MobilePage/BankListPage";
import CollectionPage from "pages/MobilePage/DepositCollectionPage";
import CollectionListPage from "pages/MobilePage/CollectionListPage";
import DepositCollectionPage from "pages/MobilePage/DepositCollectionPage";
import MyCollectorListPage from "pages/MobilePage/MyCollectorListPage";
import ConfirmPage from "components/mobile-components/CollectionDeposit/ConfirmPage";
import PreOrderSuccessPage from "pages/MobilePage/PreOrderSuccessPage";
import VoiceRecorder from "pages/MobilePage/VoiceRecorder";
import PaymentRecieptImagePage from "pages/MobilePage/PaymentRecieptImagePage";
import BazAiPage from "pages/MobilePage/BazAiPage";
import LearningDetailsPage from "pages/MobilePage/LearningDetailsPage";
import LearningPage from "pages/MobilePage/LearningPage";
import CouponListPage from "pages/MobilePage/CouponListPage";
import AddDefaultBankPage from "pages/MobilePage/AddDefaultBankPage";
import InitPaymentSettingsPage from "pages/MobilePage/InitPaymentSettingsPage";
import AddedBankListPage from "pages/MobilePage/AddedBankListPage";
import BlackListedPage from "pages/MobilePage/BlackListedPage";
import SelfieVerificationPage from "pages/MobilePage/SelfieVerificationPage";
import UtilityVerificationPage from "pages/MobilePage/UtilityVerificationPage";
import TaxVerificationPage from "pages/MobilePage/TaxVerificationPage";
import BrandAgreementVerificationPage from "pages/MobilePage/BrandAgreementVerificationPage";
import EBSAgreementPaperPage from "pages/MobilePage/EBSAgreementPaperPage";
import KycTermsConditionPage from "pages/MobilePage/KycTermsConditionPage";
import KYCInfoPage from "pages/MobilePage/KYCInfoPage";
import SelectBrandsPage from "pages/MobilePage/SelectBrandsPage";
import PublicViewPage from "pages/MobilePage/PublicViewPage";
import PromoCodeListPage from "pages/MobilePage/PromoCodeListPage";
import ProductDetailsPreviewPage from "pages/MobilePage/ProductDetailsPreviewPage";
import PurchaseOrderDetailsPage from "pages/MobilePage/PurchaseOrderDetailsPage";
import FSDueDetailsPage from "pages/MobilePage/FSDueDetailsPage";
import CreateSupplierPage from "pages/MobilePage/CreateSupplierPage";
import OrderHistoryListPage from "pages/MobilePage/OrderHistoryListPage";
import IndustryTypePage from "pages/MobilePage/IndustryTypePage";
import PickBusinessLocationPage from "pages/MobilePage/PickBusinessLocationPage";
import InvitedSupplierListPage from "pages/MobilePage/InvitedSupplierListPage";
import CardAddOnRegiPage from "pages/MobilePage/CardAddOnRegiPage";
import UpgradePlanPage from "pages/MobilePage/UpgradePlanPage";
import PaymentMethodSubscriptionPage from "pages/MobilePage/PaymentMethodSubscriptionPage";
import PaymentDetailsSubscriptionPage from "pages/MobilePage/PaymentDetailsSubscriptionPage";
import CardAddPage from "pages/MobilePage/CardAddPage";
import PaymentSuccessPage from "pages/MobilePage/PaymentSuccessPage";
import CreateOrderForAi from "pages/MobilePage/CreateOrderForAi";
import BazAiLanding from "pages/BazLanding.js";
import PhoneNumberCheckPage from "pages/BazLanding.js/PhoneNumberCheckPage";
import PasswordPage from "pages/BazLanding.js/PasswordPage";
import RegistrationPage from "pages/BazLanding.js/RegistrationPage";
import ProcurementOrderDetailsPage from "pages/MobilePage/procurementOrderDetails";
import TokenSubmitPage from "pages/BazLanding.js/TokenSubmitPage";
import ChangeForgetPasswordPage from "pages/BazLanding.js/ChangeForgetPasswordPage";
import ProcurementListPage from "pages/MobilePage/ProcurementListPage";
import CADUploadAndPreview from "components/mobile-components/BazAI/CADPreview";
import DWGViewer from "components/mobile-components/BazAI/DWGViewer";
import SuppliersPage from "pages/WebPage/SuppliersPage";
import OrdersPage from "pages/WebPage/OrdersPage";
import AssetsPage from "pages/WebPage/AssetsPage";
import BusinessRulesPage from "pages/MobilePage/BusinessRulesPage";
import ModelViewer from "components/mobile-components/BazAI/CADViewerWithServerURL";
import BazStorePage from "pages/MobilePage/BazStorePage";

function Mobile() {
	return (
		<Routes>
			<Route path='/*' element={<PrivateOutlet />}>
				<Route path='order-list' element={<OrderList />} />
				<Route
					path='product-details/:type/:id'
					element={<ProductDetailsPage />}
				/>
				<Route
					path='product-detail/:id/:type'
					element={<BrandProductDetailsPage />}
				/>
				<Route
					path='voice-search/product-detail/:id/:type'
					element={<BrandProductDetailsPage />}
				/>
				<Route path='product-list' element={<ProductListPage />} />
				<Route path='create-order' element={<CreateOrder />} />
				<Route path='create-order-ai' element={<CreateOrderForAi />} />
				<Route path='select-product' element={<SelectOrderProductPage />} />
				<Route path='location' element={<Map />} />
				<Route path='success-order/:id' element={<CongratulationPage />} />
				<Route
					path='select-product/:type'
					element={<SelectProductPage />}
				/>
				<Route
					path='order-details/:id/:orderUserType'
					element={<OrderDetailsPage />}
				/>
				<Route
					path='purchase-order-details/:id/:orderUserType'
					element={<PurchaseOrderDetailsPage />}
				/>
				<Route
					path='procurement-order-details/:id/:from'
					element={<ProcurementOrderDetailsPage />}
				/>
				<Route
					path='order-product-summary'
					element={<OrderProductSummaryPage />}
				/>
				<Route
					path='order-product-summary-details/:product'
					element={<OrderProductSummaryDetailsPage />}
				/>
				<Route
					path='order-driver-product-summary'
					element={<OrderDriverProductSummaryPage />}
				/>
				<Route
					path='order-driver-product-summary/:details'
					element={<OrderDriverProductDrilldownPage />}
				/>
				<Route
					path='order-driver-delivery-summary'
					element={<OrderDriverDeliverySummaryPage />}
				/>
				<Route
					path='order-delivery-amount-summary'
					element={<OrderDeliveryAmountSummaryPage />}
				/>
				<Route
					path='order-delivery-amount-summary/:details'
					element={<OrderDeliveryAmountDrilldownPage />}
				/>
				<Route path='buyer-summary' element={<BuyerSummaryPage />} />
				<Route
					path='buyer-summary/:details'
					element={<BuyerSummeryDrilldownPage />}
				/>
				<Route path='delay-in-payment' element={<DelayInPaymentPage />} />
				<Route
					path='delay-in-payment/:details'
					element={<DelayInPaymentDrilldownPage />}
				/>
				<Route path='driver-list' element={<MyDriversListPage />} />
				<Route path='collector-list' element={<MyCollectorListPage />} />
				<Route path='brand-details/:id' element={<BrandDetailsPage />} />
				{/* Brand Supplier Details  */}
				<Route
					path='supplier-detail/:id/:relId'
					element={<SupplierDetailPage />}
				/>
				<Route path='public-view' element={<PublicViewPage />} />
				{/* My Supplier Details */}
				<Route
					path='supplier-details/:id'
					element={<SuppliersDetailsPage />}
				/>
				<Route
					path='mysupplier-details/:id'
					element={<MySuppliersDetailsPage />}
				/>
				<Route path='business-name' element={<BusinessNamePage />} />
				<Route path='industry-type' element={<IndustryTypePage />} />
				<Route path='select-business' element={<SelectBusiness />} />
				<Route path='business-type' element={<BusinessTypePage />} />
				<Route path='nid-verification' element={<NIDVerificationPage />} />
				<Route path='take-selfie' element={<SelfieVerificationPage />} />
				<Route
					path='trade-license-verification'
					element={<TradeLicensePage />}
				/>
				<Route
					path='utility-verification'
					element={<UtilityVerificationPage />}
				/>
				<Route path='tax-verification' element={<TaxVerificationPage />} />
				<Route path='select-brands' element={<SelectBrandsPage />} />
				<Route
					path='brand-agreement-verification'
					element={<BrandAgreementVerificationPage />}
				/>
				<Route
					path='ebs-agreement-paper'
					element={<EBSAgreementPaperPage />}
				/>
				<Route
					path='ekkbaz-terms-conditons'
					element={<KycTermsConditionPage />}
				/>
				<Route
					path='card-add-on-registration'
					element={<CardAddOnRegiPage />}
				/>
				<Route
					path='utility-verification'
					element={<UtilityVerificationPage />}
				/>
				<Route path='tax-verification' element={<TaxVerificationPage />} />
				<Route
					path='brand-agreement-verification'
					element={<BrandAgreementVerificationPage />}
				/>
				<Route
					path='ebs-agreement-paper'
					element={<EBSAgreementPaperPage />}
				/>
				<Route path='notifications' element={<NotificationPage />} />
				<Route
					path='supplier-notifications'
					element={<SupplierNotificationPage />}
				/>
				<Route path='property-type' element={<PropertyTypePage />} />
				<Route path='rental-info' element={<RentalInfoPage />} />
				<Route path='business-success' element={<BusinessSuccess />} />
				<Route
					path='business-location'
					element={<BusinessLocationPage />}
				/>
				<Route path='profile' element={<ProfilePage />} />
				<Route path='contact' element={<ContactPage />} />
				<Route path='change-password' element={<ChangePasswordPage />} />
				<Route
					path='changed-password-success'
					element={<ChangePasswordSuccessPage />}
				/>
				<Route path='products/:category' element={<SeeMoreProductPage />} />
				<Route path='otp-send' element={<ProfileVarifyPage />} />
				<Route path='otp-submit' element={<ProfileVarifyOtpPage />} />
				<Route path='change-language' element={<ChangeLanguagePage />} />
				<Route path='location-enabled' element={<LocationPage />} />
				<Route path='search-page/:tarm' element={<SearchPage />} />
				<Route path='mysupplier' element={<MySupplierPage />} />
				<Route
					path='business-profile-settings'
					element={<BusinessProfilePage />}
				/>
				<Route path='pick-location/:id' element={<PickLocationPage />} />
				<Route
					path='pick-business-location'
					element={<PickBusinessLocationPage />}
				/>
				<Route path='business-edit' element={<EditBusinessPage />} />
				<Route
					path='payment-terms-settings'
					element={<PaymentSettingsPage />}
				/>
				<Route path='vbtrade-license' element={<VBTradLicensePage />} />
				<Route path='vbnid' element={<VBNidPage />} />
				<Route path='vbproperty' element={<VBPropertyPage />} />
				<Route path='vbrental-document' element={<VBRentalPage />} />
				<Route path='invoice/:id' element={<Invoice />} />
				<Route path='receipt/:id' element={<ReceiptPage />} />
				<Route
					path='search-location'
					element={<BusinessLocationPickerPage />}
				/>
				<Route
					path='employes-list/:category/:id'
					element={<EmployeesListPage />}
				/>
				<Route path='employees-list' element={<MyEmployeesListPage />} />
				<Route
					path='employee-details/:id'
					element={<EmployeesDetailsPage />}
				/>
				<Route path='create-employee' element={<CreateEmployeePage />} />
				<Route
					path='update-employee/:id'
					element={<UpdateEmployeePage />}
				/>
				<Route path='supplier-list' element={<SupplierListPage />} />
				<Route
					path='invited-supplier-list'
					element={<InvitedSupplierListPage />}
				/>
				<Route path='create-supplier' element={<CreateSupplierPage />} />
				<Route path='customer-list' element={<CustomerListPage />} />
				<Route path='brand-list' element={<BrandListPage />} />
				{/* <Route path='my-brand' element={<MyBrandListPage />} /> */}
				<Route path='my-brand' element={<BrandListPage />} />
				<Route path='all-brands' element={<AllBrandListPage />} />
				<Route path='categories' element={<CategoriesPage />} />
				<Route path='sub-categories' element={<SubCategoriesPage />} />
				<Route path='create-brand' element={<CreateBrandPage />} />
				<Route path='update-brand/:id' element={<UpdateBrandPage />} />
				<Route path='create-product' element={<CreateProductPage />} />
				<Route path='create-pack' element={<CreatePackPage />} />
				<Route path='create-offer' element={<CreateOfferPage />} />
				<Route path='update-product/:id' element={<UpdateProductPage />} />
				<Route path='update-offer/:id' element={<UpdateOfferPage />} />
				<Route
					path='brand-create-success'
					element={<CreateBrandSuccessPage />}
				/>
				<Route
					path='brand-update-success/:id'
					element={<UpdateBrandSuccessPage />}
				/>
				<Route path='department-list' element={<DepartmentListPage />} />
				<Route path='dashboard' element={<DashboardPage />} />
				<Route
					path='dashboard-details/:date/:category/:cateList'
					element={<DashboardDetailsPage />}
				/>
				<Route path='access-restrict' element={<AccessRestrictPage />} />
				<Route path='business-delete-otp' element={<OtpSubmitPage />} />
				<Route path='pay-ekkbaz/:id' element={<PayEkkbazPage />} />
				<Route
					path='customer/:id/:relationId'
					element={<CustomerDetailsPage />}
				/>
				<Route
					path='deposit-collection-list'
					element={<CollectionListPage />}
				/>{" "}
				<Route
					path='deposit-collection/:id'
					element={<DepositCollectionPage />}
				/>
				<Route path='confirm-page/:status' element={<ConfirmPage />} />
				<Route path='pre-order-success' element={<PreOrderSuccessPage />} />
				<Route path='voice-recorder' element={<VoiceRecorder />} />
				<Route
					path='fs-payment-reciept'
					element={<PaymentRecieptImagePage />}
				/>
				<Route path='cad_file' element={<ModelViewer />} />
				<Route path='dwg' element={<DWGViewer />} />
				<Route path='baz-ai' element={<BazAiPage />} />
				<Route path='baz-ai/:tid' element={<BazAiPage />} />
				<Route path='suppliers' element={<SuppliersPage />} />
				<Route path='orders' element={<OrdersPage />} />
				<Route path='assets' element={<AssetsPage />} />
				<Route path='procurements' element={<ProcurementListPage />} />
				<Route path='baz-store' element={<BazStorePage />} />
				<Route
					path='procurements/:procid'
					element={<ProcurementListPage />}
				/>
				<Route
					path='procurements/:type/:id'
					element={<ProcurementListPage />}
				/>
				<Route path='learning' element={<LearningPage />} />
				<Route
					path='learning-details/:id'
					element={<LearningDetailsPage />}
				/>
				<Route path='coupon-list' element={<CouponListPage />} />
				<Route path='promo-code-list' element={<PromoCodeListPage />} />
				<Route
					path='init-payment-terms-settings'
					element={<InitPaymentSettingsPage />}
				/>
				<Route
					path='add-default-bank-account'
					element={<AddDefaultBankPage />}
				/>
				<Route path='add-bank-account' element={<AddBankAccountPage />} />
				<Route path='bank-list/:type' element={<BankListPage />} />
				<Route path='added-bank-list' element={<AddedBankListPage />} />
				<Route path='kyc-details' element={<KYCInfoPage />} />
				<Route path='fs-due-details' element={<FSDueDetailsPage />} />
				<Route
					path='order-history-list'
					element={<OrderHistoryListPage />}
				/>
				<Route path='select-upgrade-plan' element={<UpgradePlanPage />} />
				<Route
					path='select-payment-method-for-subscription'
					element={<PaymentMethodSubscriptionPage />}
				/>
				<Route
					path='select-payment-method-for-subscription/:methodType'
					element={<PaymentDetailsSubscriptionPage />}
				/>
				<Route path='payment-success' element={<PaymentSuccessPage />} />
				<Route path='card-add' element={<CardAddPage />} />
				<Route path='card-add/:from' element={<CardAddPage />} />
				<Route path='business-rules' element={<BusinessRulesPage />} />
				<Route path='*' element={<Error />} />
				{/* <Route
					path='product-details/:id'
					element={<ProductDetailsPage />}
				/> */}
				<Route
					path='product-details/:id'
					element={<ProductDetailsPreviewPage />}
				/>
			</Route>
			{/* <Route path='/login' element={<Login />} /> */}
			{/* <Route path='/' element={<PhoneNumberCheck />} /> */}
			<Route path='/' element={<BazAiLanding />} />
			{/* <Route path='/baz-ai-landing' element={<BazAiLanding />} /> */}
			<Route path='/login' element={<PhoneNumberCheckPage />} />
			<Route path='/password' element={<PasswordPage />} />
			<Route path='/registration' element={<RegistrationPage />} />
			<Route path='/submit-token' element={<TokenSubmitPage />} />
			{/* <Route path='/change-password' element={<ChangePasswordV2 />} /> */}
			<Route
				path='/change-password'
				element={<ChangeForgetPasswordPage />}
			/>
			<Route path='/business-check' element={<CheckBusiness />} />
			<Route path='/post/en/:id' element={<SingleBlogPage />} />
			<Route path='/post/en' element={<BlogPostPage />} />
			<Route path='/refund-policy' element={<RefundPolicy />} />
			<Route path='/privacy-policy' element={<PrivacyPolicy />} />
			<Route path='/terms-conditions' element={<TermsCondition />} />
			<Route path='/auto-log-out' element={<AutoLogoutPage />} />
			<Route path='/black-listed' element={<BlackListedPage />} />
		</Routes>
	);
}

export default Mobile;
