// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-container {
	display: inline-block;
	position: relative;
}

.tooltip-box {
	position: absolute;
	padding: 2px 10px;
	background: #333;
	color: #fff;
	border-radius: 4px;
	font-size: 0.9rem;
	z-index: 1000;
	white-space: nowrap;
	visibility: visible;
	opacity: 1;
	transition: opacity 0.3s ease-in-out;
}

.tooltip-box.tooltip-top {
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	margin-bottom: 8px;
}

.tooltip-box.tooltip-bottom {
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	margin-top: 8px;
}

.tooltip-box.tooltip-left {
	right: 100%;
	top: 50%;
	transform: translateY(-50%);
	margin-right: 8px;
}

.tooltip-box.tooltip-right {
	left: 100%;
	top: 50%;
	transform: translateY(-50%);
	margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/global-components/common/Tooltip.css"],"names":[],"mappings":"AAAA;CACC,qBAAqB;CACrB,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;CAClB,iBAAiB;CACjB,gBAAgB;CAChB,WAAW;CACX,kBAAkB;CAClB,iBAAiB;CACjB,aAAa;CACb,mBAAmB;CACnB,mBAAmB;CACnB,UAAU;CACV,oCAAoC;AACrC;;AAEA;CACC,YAAY;CACZ,SAAS;CACT,2BAA2B;CAC3B,kBAAkB;AACnB;;AAEA;CACC,SAAS;CACT,SAAS;CACT,2BAA2B;CAC3B,eAAe;AAChB;;AAEA;CACC,WAAW;CACX,QAAQ;CACR,2BAA2B;CAC3B,iBAAiB;AAClB;;AAEA;CACC,UAAU;CACV,QAAQ;CACR,2BAA2B;CAC3B,gBAAgB;AACjB","sourcesContent":[".tooltip-container {\n\tdisplay: inline-block;\n\tposition: relative;\n}\n\n.tooltip-box {\n\tposition: absolute;\n\tpadding: 2px 10px;\n\tbackground: #333;\n\tcolor: #fff;\n\tborder-radius: 4px;\n\tfont-size: 0.9rem;\n\tz-index: 1000;\n\twhite-space: nowrap;\n\tvisibility: visible;\n\topacity: 1;\n\ttransition: opacity 0.3s ease-in-out;\n}\n\n.tooltip-box.tooltip-top {\n\tbottom: 100%;\n\tleft: 50%;\n\ttransform: translateX(-50%);\n\tmargin-bottom: 8px;\n}\n\n.tooltip-box.tooltip-bottom {\n\ttop: 100%;\n\tleft: 50%;\n\ttransform: translateX(-50%);\n\tmargin-top: 8px;\n}\n\n.tooltip-box.tooltip-left {\n\tright: 100%;\n\ttop: 50%;\n\ttransform: translateY(-50%);\n\tmargin-right: 8px;\n}\n\n.tooltip-box.tooltip-right {\n\tleft: 100%;\n\ttop: 50%;\n\ttransform: translateY(-50%);\n\tmargin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
