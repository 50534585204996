import { IonApp, IonButton, IonContent, IonPage } from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import withLocation from "components/HOC/withLocation";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Map from "components/mobile-components/LocationPicker/Map";
import MapFunctional from "components/mobile-components/LocationPicker/MapFunctional";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function BusinessLocationPickerPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();
	const [error, setError] = useState();
	const location = useSelector((state) => state.user.mapLocation);
	const myLocation = useSelector(
		(state) => state.user?.locationData?.coordinates
	);

	return (
		<IonApp>
			<IonPage className='business-location'>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.locationPicker.pageTitle} />
					<div className='pt-14'>
						{/* <Map
						// google={this.props.google}
						center={{ lat: 23.5204, lng: 90.399452 }}
						height='500px'
						zoom={15}
					/> */}

						<MapFunctional
							center={
								myLocation && myLocation[0] && myLocation[1]
									? {
											lng: myLocation[0],
											lat: myLocation[1],
									  }
									: {
											lng: location?.lat ? location?.lat : 23.5204,
											lat: location?.lng ? location?.lng : 90.399452,
									  }
							}
							height='400px'
							zoom={16}
							location={location}
						/>
						<div
							className='mx-4 mt-5 mb-10'
							style={{
								background: "#FFFFCC",
								boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
							}}>
							<div className='rounded-[5px]'>
								<p className='text-808080 text-12 font-semibold px-[10px] py-[6px]'>
									{ekkLanguage.locationPicker.paragraph}
								</p>
							</div>
						</div>
						{location?.lat && location?.lng && (
							<div className='pt-24 w-full flex justify-center'>
								<IonButton
									color='success'
									className='font-bold h-10 w-full'
									onClick={() => {
										if (Object.keys(location).length) {
											navigate("/business-location");
										} else {
											setError(
												ekkLanguage.businessCreateName.errorLabel
											);
										}
									}}>
									{ekkLanguage.businessCreateName.btnLabel}
									<span className='pl-2'>
										<ArrayRight />
									</span>
								</IonButton>
							</div>
						)}
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
export default withLocation(BusinessLocationPickerPage);
