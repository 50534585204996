import SuperFetch from "config/SuperFetch";
import { baseUrl } from "config/apiUrl";

const Procurement = {
	procurementList: (page = 1, limit = 10) => {
		return SuperFetch(
			`${baseUrl.procurement}/procurements/?page=${page}&limit=${limit}`
		);
	},
	procurementDetails: (id) => {
		return SuperFetch(`${baseUrl.procurement}/procurements/${id}/`);
	},
	acceptRejectProcurement: (id, data) => {
		return SuperFetch(
			`${baseUrl.procurement}/procurement_approve_reject/${id}/`,
			{
				method: "PUT",
				data: data,
			}
		);
	},
	publishProcurement: (id, data) => {
		return SuperFetch(`${baseUrl.procurement}/procurement_publish/${id}/`, {
			method: "PUT",
		});
	},
	bidList: (id, page = 1, limit = 10) => {
		return SuperFetch(
			`${baseUrl.procurement}/procurementbid/${id}/?page=${page}&limit=${limit}`,
			{}
		);
	},
	createBid: (data) => {
		return SuperFetch(`${baseUrl.procurement}/procurementbid/`, {
			method: "POST",
			data: data,
		});
	},
	evaluationFeedBack: (data) => {
		return SuperFetch(`${baseUrl.procurement}/procurementfeedback/`, {
			method: "POST",
			data: data,
		});
	},
	createAward: (id, data) => {
		return SuperFetch(`${baseUrl.procurement}/procurementaward/${id}/`, {
			method: "PUT",
			data: data,
		});
	},
	getProcurementList: (page = 1, limit = 20) => {
		return SuperFetch(
			`${baseUrl.procurement}/ekkbaz/procurement/?page=${page}&limit=${limit}`
		);
	},
	uploadGLBFile: (data, threadId) => {
		return SuperFetch(
			`${baseUrl.procurement}/sendCADFileData/?threadID=${threadId}`,
			{
				method: "POST",
				data: data,
			}
		);
	},
};
export default Procurement;
