import BazAi from "components/mobile-components/BazAI";
import BazAiNav from "components/mobile-components/Common/BazAiNav";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import NotificationList from "components/mobile-components/Notifications";
import { getLocalUser, getUser, getUserBusiness } from "config/user";
import { getLanguage } from "config/language";
import Notification from "services/Notification";
import {
	setCustomerUnreadCount,
	setNotification,
	setSupplierUnreadCount,
} from "redux/container/notificationSlice";
import {
	setLoadMessage,
	setThreadId,
	setThreadList,
} from "redux/container/bazAiSlice";

import BottomMenu from "components/global-components/common/BottomMenu";
import BazAiApi from "services/BazAi";
import moment from "moment";

export default function BazAiPage() {
	const { tid } = useParams();
	const location = useLocation();
	const contentRef = useRef();
	const user = getUser() ? getUser() : getLocalUser()?.user;
	const threadId = useSelector((state) => state?.bazAi?.threadId);
	const threadList = useSelector((state) => state?.bazAi?.threadList);
	const messageData = useSelector((state) => state?.bazAi?.data);
	const { pathname } = useLocation();
	const [loading, setLoading] = useState(false);
	const [notificationList, setNotificationList] = useState([]);
	const [notificationType, setNotificationType] = useState("customer");
	const dispatch = useDispatch();
	const id = getUserBusiness()?.id;
	const localLanguage = getLanguage();
	const [notificationCount, setNotificationCount] = useState("");
	const business = getUserBusiness();
	const navigate = useNavigate();

	var windowSize = window.matchMedia("(max-width: 1100px)");

	// const [showLeft, setShowLeft] = useState(!windowSize.matches ? true : false);
	const [showLeft, setShowLeft] = useState(true);
	const [showRight, setShowRight] = useState(false);

	const scrollToBottom = () => {
		if (contentRef.current) {
			contentRef.current.scrollToBottom();
		}
	};
	useEffect(() => {
		scrollToBottom();
	}, [messageData?.length]);

	const menuIconClick = () => {
		setShowLeft(!showLeft);
	};

	let textContentRef = useRef();

	// let lastItemMessage = messageData[messageData?.length - 1]?.message;
	// let lastItemType = messageData[messageData?.length - 1]?.type;
	// let selfMessage = messageData[messageData?.length - 1]?.receiver;
	// let self = messageData[messageData?.length - 1]?.user_name !== "Bot";

	let loadingMessage =
		messageData?.length > 0
			? messageData[messageData?.length - 1]?.loading
			: false;
	useEffect(() => {
		textContentRef.current.scrollTop = textContentRef.current.scrollHeight;
	}, [loadingMessage]);

	let getNotificationList = async () => {
		let response = await Notification.getProcurementNotifications();
		if (response.success) {
			setNotificationCount(response?.unread);
			dispatch(setNotification([...notificationList, ...response?.results]));
			setNotificationList([...notificationList, ...response?.results]);
		}
	};
	let getNotificationListDetails = async (selectedNotification) => {
		navigate(
			`/procurement-order-details/${Number(
				selectedNotification?.service_field_id
			)}/${business?.role}`
		);
		let response = await Notification.getProcurementNotificationsDetails(
			selectedNotification?.id
		);
		if (response.success) {
			dispatch(setNotification([...notificationList, ...response?.results]));
			setNotificationList([...notificationList, ...response?.results]);
		}
	};

	const getThreadList = async () => {
		let response = await BazAiApi.getThreads();
		if (response.status) {
			dispatch(setThreadList(response.results));
			if (!threadId) {
				dispatch(setThreadId(response.results[0]?.thread));
			}
		}
	};
	const getMessage = async (id) => {
		dispatch(setThreadId(id));
		let response = await BazAiApi.getMessage(id);
		if (response.status) {
			const transformedMessages = response?.results?.chat?.map((msg) => {
				const isBot = !msg.is_user;

				if (msg.card_type === "cad-card") {
					return {
						user_name: isBot ? user?.DisplayName : "Bot",
						receiver: isBot,
						created_at: moment(msg.createdAt).format("LLLL"),
						avatar: isBot
							? "assets/images/bot-s.png"
							: user?.profile_image,
						loading: false,
						cardType: "infoCard",
						...(isBot
							? { message: msg.chat }
							: { text: msg.chat, type: "text" }),
					};
				} else if (msg.card_type === "bom-card") {
					return {
						user_name: isBot ? user?.DisplayName : "Bot",
						receiver: isBot,
						created_at: moment(msg.createdAt).format("LLLL"),
						avatar: isBot
							? "assets/images/bot-s.png"
							: user?.profile_image,
						loading: false,
						type: "success",
						bomJson: JSON.parse(msg?.cad_data),
						cardType: "bomCard",
						...(isBot
							? { message: msg.chat }
							: { text: msg.chat, type: "text" }),
					};
				} else if (msg.card_type === "procurement") {
					return {
						user_name: isBot ? user?.DisplayName : "Bot",
						receiver: isBot,
						created_at: moment(msg.createdAt).format("LLLL"),
						avatar: isBot
							? "assets/images/bot-s.png"
							: user?.profile_image,
						loading: false,
						type: "success",
						produermenId: msg?.procurement_id,
						cardType: "procurementCard",
						...(isBot
							? {
									message:
										"Your procurement request is successfully created.",
							  }
							: {
									text: "Your procurement request is successfully created.",
									type: "text",
							  }),
					};
				} else if (msg.card_type === "email") {
					return {
						user_name: isBot ? user?.DisplayName : "Bot",
						receiver: isBot,
						created_at: moment(msg.createdAt).format("LLLL"),
						avatar: isBot
							? "assets/images/bot-s.png"
							: user?.profile_image,
						loading: false,
						type: "success",
						cardType: "emailCard2",
						...(isBot
							? { message: msg.chat }
							: { text: msg.chat, type: "text" }),
					};
				} else {
					return {
						user_name: isBot ? user?.DisplayName : "Bot",
						receiver: isBot,
						created_at: moment(msg.createdAt).format("LLLL"),
						avatar: isBot
							? "assets/images/bot-s.png"
							: user?.profile_image,
						loading: false,
						cardType: "infoCard",
						...(isBot
							? { message: msg.chat }
							: { text: msg.chat, type: "text" }),
					};
				}
			});

			dispatch(setLoadMessage(transformedMessages));
		}
	};

	useEffect(() => {
		getNotificationList();
	}, [notificationType]);

	useEffect(() => {
		getThreadList();
	}, []);

	return (
		<div
			className={`relative bg-white h-screen flex flex-col overflow-y-auto ${
				showLeft ? "pl-[240px]" : "pl-[0]"
			}`}
			style={{ transition: ".2s" }}
			ref={textContentRef}>
			<BazAiNav
				title='Baz AI'
				menuIconClick={menuIconClick}
				showLeft={showLeft}
				showRight={showRight}
				setShowRight={setShowRight}
				notificationCount={notificationCount}
			/>
			<div>
				<div
					className={`fixed top-0 left-0 h-full  shadow-lg z-50 transition-transform duration-300 ease-in-out ${
						showLeft ? "translate-x-0" : "-translate-x-full"
					}`}
					style={{ width: "240px", background: "#f7f7f7" }}>
					<div className='p-4'>
						{/* <div className='flex justify-between items-center'>
							<span onClick={menuIconClick}>
								<File />
							</span>
							<span onClick={() => dispatch(clearThread())}>
								<NewPen />
							</span>
						</div> */}
						<div className='flex items-center'>
							<img
								onClick={() => navigate("/baz-ai")}
								src='/assets/images/bot-m.png'
								className='w-6 mr-2 cursor-pointer'
								alt='logo'
							/>

							<Link
								to='/baz-ai'
								className={`text-dark-1000 text-18 font-bold ${"bg-[#d3d3d3] rounded-lg"} p-2 flex items-center`}>
								Baz AI
							</Link>
						</div>
						<div className='mt-4 overflowY-auto'>
							<ul className='mt-2 space-y-2'>
								{threadList?.length
									? threadList?.map((el, i) => {
											return (
												<li key={i}>
													<p
														onClick={() =>
															el?.thread &&
															getMessage(el?.thread)
														}
														className={`${
															el?.thread == threadId &&
															"bg-d3d3d3"
														} hover:bg-d3d3d3 p-2 rounded-[5px] cursor-pointer`}
														style={{
															fontSize: "0.875rem",
															lineHeight: "1.25rem",
															transition: ".2s",
														}}>
														{el?.title?.length > 27
															? el?.title?.substring(0, 27) +
															  "..."
															: el?.title}
													</p>
												</li>
											);
									  })
									: undefined}
							</ul>
						</div>
					</div>
				</div>
				<div className='flex-1 pt-12 container'>
					<BazAi showLeft={showLeft} getThreadList={getThreadList} />
				</div>
			</div>

			<BottomMenu threadId={threadId} getThreadList={getThreadList} />
		</div>
	);
}
