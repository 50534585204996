import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"; // Optional for interaction

const ModelViewer = ({
	// url = "https://modelviewer.dev/shared-assets/models/shishkebab.glb",
	url = "https://ekkfilesdbt.blob.core.windows.net/userssblobs/760bc938-5fea-4fe6-bfca-9f9a16a5ebb7_bmw_i8 1.glb?se=2025-01-15T16%3A48%3A21Z&sp=r&sv=2025-01-05&sr=c&sig=hmvjIAQUayswJYltm7kmxrsN2YubJtvKMLIUPFb2/Kw%3D",
}) => {
	// https://ekkfilesdbt.blob.core.windows.net/cadfileblobs/d4b5c033-5e56-4ed6-83e5-85706750f3abshishkebab.glb

	const viewerRef = useRef(null);

	useEffect(() => {
		// Scene setup
		const scene = new THREE.Scene();
		const camera = new THREE.PerspectiveCamera(75, 400 / 400, 0.1, 1000);
		const renderer = new THREE.WebGLRenderer({ antialias: true });
		renderer.setSize(600, 600);
		viewerRef.current.appendChild(renderer.domElement);

		// Add lights to the scene
		const light1 = new THREE.DirectionalLight(0xffffff, 1);
		light1.position.set(5, 5, 5).normalize();
		scene.add(light1);

		const light2 = new THREE.AmbientLight(0xffffff, 0.5);
		scene.add(light2);

		// Load GLB model
		const loader = new GLTFLoader();

		if (typeof url !== "string") {
			console.error("Model URL must be a string");
			return;
		}

		loader.load(
			url,
			(gltf) => {
				// Scale and center the model
				gltf.scene.scale.set(0.5, 0.5, 0.5); // Adjust the scale as needed
				scene.add(gltf.scene); // Add the loaded model to the scene

				const box = new THREE.Box3().setFromObject(gltf.scene);
				const center = box.getCenter(new THREE.Vector3());
				gltf.scene.position.sub(center); // Center the model

				// Automatically adjust camera based on model size
				const size = new THREE.Vector3();
				box.getSize(size);
				const maxDim = Math.max(size.x, size.y, size.z);
				camera.position.z = maxDim * 1.5; // Adjust camera distance based on the model's size

				renderer.render(scene, camera); // Initial render
			},
			undefined,
			(error) => {
				console.error("Error loading model:", error);
			}
		);

		// Set up camera controls (optional for interaction)
		const controls = new OrbitControls(camera, renderer.domElement);

		// Animation loop
		const animate = () => {
			requestAnimationFrame(animate);
			controls.update(); // Update the camera controls
			renderer.render(scene, camera);
		};
		animate();

		// Handle window resize
		const handleResize = () => {
			const width = viewerRef.current.clientWidth;
			const height = viewerRef.current.clientHeight;
			renderer.setSize(width, height);
			camera.aspect = width / height;
			camera.updateProjectionMatrix();
		};

		window.addEventListener("resize", handleResize);

		// Cleanup on unmount
		return () => {
			window.removeEventListener("resize", handleResize);
			renderer.dispose();
			viewerRef.current.removeChild(renderer.domElement);
		};
	}, [url]);

	return (
		<div
			ref={viewerRef}
			style={{
				minWidth: "200px",
				minHeight: "200px", // Ensure the container has a minimum size
				overflow: "hidden",
			}}
		/>
	);
};

export default ModelViewer;
