import React, { useEffect, useRef } from "react";

export default function TextInput({
	type,
	title,
	inputText,
	setInputText,
	placeholder,
	textarea,
	required,
}) {
	const numberInputRef = useRef(null);
	useEffect(() => {
		const handleWheel = (event) => {
			if (
				numberInputRef.current &&
				numberInputRef.current === document.activeElement
			) {
				event.preventDefault();
			}
		};
		window.addEventListener("wheel", handleWheel, { passive: false });
		return () => {
			window.removeEventListener("wheel", handleWheel);
		};
	}, []);
	return (
		<div className='pb-7'>
			{title && (
				<h4 className='pb-3 font-bold text-16'>
					{title} {required && <span className='text-primary'>*</span>}
				</h4>
			)}
			<>
				{!textarea ? (
					<input
						ref={numberInputRef}
						className='pl-4 h-[50px] rounded-[5px] bg-white placeholder-black-500 w-full border border-gray-300'
						// style={{ boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.05)" }}
						type={type}
						placeholder={placeholder}
						value={inputText}
						onChange={(e) => setInputText(e.target.value)}
					/>
				) : (
					<textarea
						className='pl-4 pt-2 h-[150px] rounded-[5px] bg-white placeholder-black-500 w-full border border-gray-300'
						// style={{ boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.05)" }}
						placeholder={placeholder}
						value={inputText}
						onChange={(e) => setInputText(e.target.value)}></textarea>
				)}
			</>
		</div>
	);
}
