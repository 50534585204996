import moment from "moment";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBomUpdated, setUpdateMessage } from "redux/container/bazAiSlice";
import Procurement from "services/Procurement";
import { v4 as uuidv4 } from "uuid";

export default function BazAiBottomChatArea({
	textareaRef,
	messageData,
	handleKeyDown,
	height,
	handleInputChange,
	setComment,
	comment,
	handleAssistant,
	timer,
	formatTime,
	getRootProps,
	getInputProps,
	handleTouchStart,
	handleTouchEnd,
	ripple,
	newSenderItemHandler,
	updateBeforeLastDataHandler,
	materials,
}) {
	const [showDropUp, setShowDropUp] = useState(false);
	const fileInputRef = useRef(null);
	const [fileUrl, setFileUrl] = useState(null);
	const [jsonUrl, setJsonUrl] = useState(null);
	let dispatch = useDispatch();

	// Handle file upload for CAD files
	const handleFileUpload = (event) => {
		dispatch(setBomUpdated(true));
		const files = Array.from(event.target.files);
		const cadFile = files.find(
			(file) =>
				file.name.endsWith(".glb") ||
				file.name.endsWith(".dwg") ||
				file.name.endsWith(".fbx") ||
				file.name.endsWith(".dxf")
			// file.name.endsWith(".obj") ||
			// file.name.endsWith(".usdz")
		);
		// Get CAD file extension
		const cadFileExtension = cadFile?.name?.split(".").pop();

		const jsonFile = files.find((file) => file.name.endsWith(".json"));

		if (cadFile) {
			const cadFileUrl = URL.createObjectURL(cadFile);
			setFileUrl(cadFileUrl);

			console.log(cadFileUrl);

			// If DXF, read file content and handle after reading
			if (cadFileExtension === "dxf") {
				const reader = new FileReader();
				reader.onload = (e) => {
					const fileContent = e.target.result;

					// Build the data object and handle it
					const data = {
						fileExtension: cadFileExtension,
						mainFile: cadFile,
						cadFile: fileContent, // Use the DXF content
						cadUrl: cadFileUrl,
						type: "cad",
						jsonFile: jsonFile,
						jsonUrl: jsonFile ? URL.createObjectURL(jsonFile) : null,
						jsonFileName: jsonFile ? jsonFile.name : null,
						cadFileExtension: cadFileExtension,
					};

					// Send the original data to the handler
					newSenderItemHandler(data);
					// Send the file to the server as binary data
					// sendBinaryToServer({ cadFile, jsonFile })
					// 	.then((response) => {
					// 		if (response) {
					// 			updateProductDataHandler(
					// 				{
					// 					type: "success",
					// 					cardType: "procurementCard",
					// 					supplier: response?.business_relation_data,
					// 					produrmenId: response?.results?.id,
					// 				},
					// 				{
					// 					type: "success",
					// 					jsonUrl: response?.files?.json_url,
					// 					bomJson: response?.json_bom_data?.Category,
					// 					cardType: "bomCard",
					// 				},
					// 				{
					// 					mailContent: response?.email_content,
					// 					initMailContent: response?.email_content,
					// 					emailAddress:
					// 						response?.business_relation_data?.map(
					// 							(supplier) => ({ email: supplier?.email })
					// 						),
					// 					produrmenId: response?.results?.id,
					// 					type: "success",
					// 					cardType: "emailCard",
					// 					mailSend: false,
					// 				}
					// 			);
					// 		}
					// 	})
					// 	.catch((error) => {
					// 		console.error("Error uploading file:", error);
					// 	});
				};
				reader.readAsText(cadFile);
			} else {
				// Handle other CAD file types
				const data = {
					fileExtension: cadFileExtension,
					mainFile: cadFile,
					cadFile: cadFile,
					cadUrl: cadFileUrl,
					type: "cad",
					jsonFile: jsonFile,
					jsonUrl: jsonFile ? URL.createObjectURL(jsonFile) : null,
					jsonFileName: jsonFile ? jsonFile.name : null,
					cadFileExtension: cadFileExtension,
				};

				// Send the original data to the handler
				newSenderItemHandler(data);

				// Send the file to the server as binary data
				// if (materials?.length) {
				// 	sendBinaryToServer({ cadFile, jsonFile })
				// 		.then((response) => {
				// 			if (response) {
				// 				updateProductDataHandler(
				// 					{
				// 						type: "success",
				// 						cardType: "procurementCard",
				// 						supplier: response?.business_relation_data,
				// 						produrmenId: response?.results?.id,
				// 					},
				// 					{
				// 						type: "success",
				// 						jsonUrl: response?.files?.json_url,
				// 						bomJson: response?.json_bom_data?.Category,
				// 						cardType: "bomCard",
				// 					},
				// 					{
				// 						mailContent: response?.email_content,
				// 						initMailContent: response?.email_content,
				// 						emailAddress:
				// 							response?.business_relation_data?.map(
				// 								(supplier) => ({ email: supplier?.email })
				// 							),
				// 						produrmenId: response?.results?.id,
				// 						type: "success",
				// 						cardType: "emailCard",
				// 						mailSend: false,
				// 					}
				// 				);
				// 			}
				// 		})
				// 		.catch((error) => {
				// 			console.error("Error uploading file:", error);
				// 		});
				// }
			}
		} else {
			console.error("No CAD file found");
		}

		if (jsonFile) {
			const jsonFileUrl = URL.createObjectURL(jsonFile);
			setJsonUrl(jsonFileUrl);
		} else {
			console.error("No JSON file found");
		}

		setShowDropUp(false);
	};
	const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
	// Send binary data to server for CAD files
	const sendBinaryToServer = async ({ cadFile, jsonFile }) => {
		const formData = new FormData();
		formData.append("cad_file", cadFile);
		// formData.append("cad_data_file", jsonFile);
		formData.append("cad_data", JSON.stringify(materials));

		try {
			let response = await Procurement.uploadGLBFile(formData);
			if (response.status === 200 || response.status === 201) {
				return response;
			} else {
				throw new Error("Failed to upload file.");
			}
		} catch (error) {
			console.error("Failed to send file to server:", error);
		}
	};

	const updateProductDataHandler = (procurement, bom, email, cad) => {
		let data = [
			{
				id: messageData?.length + 1,
				uid: uuidv4(),
				user_name: "Bot",
				receiver: true,
				created_at: moment().format("LLLL"),
				avatar: "assets/images/bot-s.png",
				loading: false,
				...bom,
			},
			{
				id: messageData?.length + 3,
				uid: uuidv4(),
				user_name: "Bot",
				receiver: true,
				created_at: moment().format("LLLL"),
				avatar: "assets/images/bot-s.png",
				loading: false,
				...procurement,
			},

			{
				id: messageData?.length + 2,
				uid: uuidv4(),
				user_name: "Bot",
				receiver: true,
				created_at: moment().format("LLLL"),
				avatar: "assets/images/bot-s.png",
				loading: false,
				...email,
			},
		];

		dispatch(setUpdateMessage(data));
	};

	useEffect(() => {
		return () => {
			if (fileUrl) {
				URL.revokeObjectURL(fileUrl);
			}
			if (jsonUrl) {
				URL.revokeObjectURL(jsonUrl);
			}
		};
	}, [fileUrl, jsonUrl]);

	const toggleDropUp = () => {
		setShowDropUp(!showDropUp);
	};

	const handleFileClick = () => {
		fileInputRef.current.click();
	};

	return (
		<>
			<div className='flex items-center relative py-5 px-4'>
				<div className='flex items-center w-[calc(100%-100px)]'>
					{/* Plus icon to toggle drop-up */}
					<span
						onClick={toggleDropUp}
						className='cursor-pointer'
						style={
							messageData?.length > 0 &&
							messageData[messageData?.length - 1]?.loading
								? {
										pointerEvents: "none",
										cursor: "not-allowed",
										opacity: 0.5,
								  }
								: {}
						}>
						{/* <ion-icon
							style={{ "--ionicon-stroke-width": "25px" }}
							name='add-circle'
							color='light'></ion-icon> */}
						<img
							className='w-7 h-7'
							src='/assets/images/bazai/add.png'
							alt='icon'
						/>
					</span>

					{/* Drop-up for file upload */}
					{showDropUp && (
						<div className='absolute bottom-[70px] bg-white shadow-xl rounded p-2'>
							<div>
								<div
									onClick={handleFileClick}
									className='cursor-pointer py-2 px-2  rounded  font-bold flex w-full items-center space-x-2 bg-gray-200 hover:bg-gray-400 hover:text-white'>
									<img
										src='/assets/images/bazai/attachment.png'
										alt='upload'
										className='w-6 h-6'
									/>
									<p>Upload CAD file</p>
								</div>
								<input
									type='file'
									accept='.glb,.fbx,.dwg,.json,.dxf'
									ref={fileInputRef}
									style={{ display: "none" }}
									onChange={handleFileUpload}
									multiple
								/>
							</div>
						</div>
					)}

					<div className='flex items-center relative w-full flex-1 ml-2 baz-ai-text-input'>
						<textarea
							ref={textareaRef}
							readOnly={
								messageData?.length > 0 &&
								messageData[messageData?.length - 1]?.loading
							}
							onKeyDown={handleKeyDown}
							style={{
								height: `${height}px`,
								overflow: height === 120 ? "auto" : "hidden",
								resize: "none",
								width: "100%",
								padding: "10px",
								paddingTop: "15px",
								boxSizing: "border-box",
								paddingRight: 35,
								borderRadius: 15,
								borderColor: "#999",
							}}
							onChange={(e) => {
								handleInputChange(e);
								setComment(e.target.value);
							}}
							value={comment}
							type='text'
							placeholder='Enter your question'
							className='gpt-text-area py-3.5 pr-10 px-3 appearance-none block mr-1 border leading-tight focus:outline-1 bg-white focus:bg-white text-14 w-full'
						/>
						<p
							className='absolute right-[14px] bottom-[12px]'
							onClick={handleAssistant}>
							<img
								className='w-7 h-7'
								src='/assets/images/bazai/send.png'
								alt='icon'
							/>
							{/* <ion-icon name='send-outline' color='success'></ion-icon> */}
						</p>
					</div>
				</div>

				<div className='flex items-center justify-between bg-white rounded-[47px] h-12 w-[100px] relative'>
					{timer ? (
						<div className='absolute top-[-28px] left-[64px]'>
							<h4
								className='text-14 font-bold pt-2 text-center'
								style={{ color: "#FF564C" }}>
								{formatTime(timer)}
							</h4>
						</div>
					) : undefined}

					<span
						style={
							messageData?.length > 0 &&
							messageData[messageData?.length - 1]?.loading
								? {
										pointerEvents: "none",
										cursor: "not-allowed",
										opacity: 0.5,
								  }
								: {}
						}
						{...getRootProps({ className: "dropzone" })}
						onContextMenu={(e) => e.preventDefault()}
						className='image mx-1'>
						<input {...getInputProps()} />
						<img
							className='w-7 h-7'
							src='/assets/images/bazai/attachment.png'
							alt='icon'
						/>
					</span>
					<span
						onContextMenu={(e) => e.preventDefault()}
						style={
							messageData?.length > 0 &&
							messageData[messageData?.length - 1]?.loading
								? {
										pointerEvents: "none",
										cursor: "not-allowed",
										opacity: 0.5,
								  }
								: {}
						}
						className={`${ripple ? "ripple mic mx-1" : "mic mx-1"}`}
						onMouseDown={
							messageData?.length > 0 &&
							!messageData[messageData?.length - 1]?.loading
								? handleTouchStart
								: undefined
						}
						onMouseUp={handleTouchEnd}
						onTouchStart={
							messageData?.length > 0 &&
							!messageData[messageData?.length - 1]?.loading
								? handleTouchStart
								: undefined
						}
						onMouseLeave={handleTouchEnd}
						onTouchEnd={handleTouchEnd}>
						<img
							className='w-7 h-7'
							src='/assets/images/bazai/mic.png'
							alt='icon'
						/>
					</span>
				</div>
			</div>
		</>
	);
}
