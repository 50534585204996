import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import "react-responsive-modal/styles.css";
import Procurement from "services/Procurement";
import { toast } from "react-toastify";
import { getUserBusiness } from "config/user";
import { ClipLoader } from "react-spinners";

const modalCustomStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "10px",
		width: "400px",
		maxWidth: "100%",
		height: "auto",
		overflow: "visible",
		transition: ".4s all ease",
		borderRadius: 12,
		backgroundColor: "#FFFFFF",
	},
	overlay: {
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		transition: ".4s all ease",
		backgroundColor: "rgba(0,0,0,.5)",
	},
};

function ActionButtonsCard({
	status,
	onDecisionClick,
	setUpdatePage,
	hasJustification,
	role,
}) {
	const { id, from } = useParams();
	const [showModal, setShowModal] = useState(false);
	const [remarks, setRemarks] = useState("");
	const [showBidModal, setShowBidModal] = useState(false);
	const [bidPrice, setBidPrice] = useState("");
	const loggedInBusinessId = getUserBusiness()?.id;
	const [loadingPublish, setLoadingPublish] = useState(false);

	// Function to open the modal
	const openModal = () => {
		setShowModal(true);
	};

	// Function to close the modal
	const closeModal = () => {
		setShowModal(false);
		setRemarks("");
	};

	// Function to handle form submission (Publishing)
	const handlePublish = async () => {
		setLoadingPublish(true);
		const response = await Procurement.publishProcurement(id);
		if (response.status === 200) {
			setLoadingPublish(false);
			setUpdatePage(true);
			onDecisionClick("Publish", remarks);
			closeModal();
			toast.success("Procurement published successfully");
		} else {
			setLoadingPublish(false);
			toast.error("Something went wrong");
		}
	};

	// Function to open the bid modal
	const openBidModal = () => {
		setShowBidModal(true);
	};

	// Function to close the bid modal
	const closeBidModal = () => {
		setShowBidModal(false);
		setBidPrice("");
	};

	// Function to handle bid submission
	const handleBidSubmit = async () => {
		if (!bidPrice) {
			toast.error("Please enter a bid price");
			return;
		}
		const data = {
			procurement: id,
			business_id: loggedInBusinessId,
			price: bidPrice,
		};
		const response = await Procurement.createBid(data);
		if (response.status === 200 || response.status === 201) {
			setUpdatePage(true);
			toast.success("Bid submitted successfully");
		} else {
			toast.error("Something went wrong");
		}
		closeBidModal();
	};

	return (
		<div className='w-full flex flex-col items-center space-y-4 px-4 pb-10 pt-4'>
			{/* Conditionally render buttons based on role */}
			{role === "Procurement Staff" && status !== "Awarded" ? (
				<div className='w-full flex justify-center space-x-4'>
					<button
						className='bg-success hover:bg-green-700 w-[300px] mr-5 text-white font-bold py-2 px-4 rounded'
						onClick={() => console.log("Edit")}>
						Edit
					</button>
					<button
						className='w-[300px] bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded'
						onClick={() => console.log("Cancel")}>
						Cancel
					</button>
				</div>
			) : (
				<>
					{/* Show Accept and Reject buttons if status is "Submission" */}
					{status === "Submission" &&
						hasJustification &&
						from !== "supplier" && (
							<div className='w-full flex justify-between space-x-4'>
								<button
									className='flex-grow bg-success hover:bg-green-700 text-white font-bold py-2 px-4 rounded'
									onClick={() => onDecisionClick("Accept")}>
									Accept
								</button>
								<button
									className='flex-grow bg-primary hover:bg-red-700 text-white font-bold py-2 px-4 rounded'
									onClick={() => onDecisionClick("Reject")}>
									Reject
								</button>
							</div>
						)}

					{/* Show Publish button if status is "Divisional Approval" */}
					{(status === "Divisional Approval" ||
						(status === "Submission" && !hasJustification)) &&
						from !== "supplier" && (
							<div className='w-full flex justify-center'>
								<button
									className='bg-success w-[300px] hover:bg-green-600 text-white font-bold py-3 px-4 rounded text-lg'
									onClick={openModal}>
									Publish
								</button>
							</div>
						)}

					{/* Show Print, Decline, and Create Quotation buttons if from is "supplier" */}
					{from === "supplier" && (
						<div className='w-full flex justify-between space-x-4'>
							<button className='flex-grow bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded'>
								Print
							</button>
							<button className='flex-grow bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded'>
								Decline
							</button>
							<button
								className='flex-grow bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded'
								onClick={openBidModal}>
								Create Bid
							</button>
						</div>
					)}
				</>
			)}
			{/* React Modal for Publishing */}
			<Modal
				isOpen={showModal}
				style={modalCustomStyles}
				closeTimeoutMS={200}
				onRequestClose={closeModal}
				contentLabel='Publish Remark Modal'>
				<h2 className='text-lg font-bold mb-4'>Publish Remarks</h2>
				<textarea
					className='w-full h-40 border border-gray-300 p-2 rounded-md'
					value={remarks}
					onChange={(e) => setRemarks(e.target.value)}
					placeholder='Enter your remarks here...'
				/>
				<div className='flex justify-end space-x-4 mt-4'>
					<button
						className='bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded'
						onClick={closeModal}>
						Cancel
					</button>
					<button
						className='bg-success hover:bg-green-600 text-white py-2 px-4 rounded flex items-center'
						onClick={!loadingPublish && handlePublish}>
						{loadingPublish ? (
							<ClipLoader
								sizeUnit={"px"}
								size={20}
								color={"white"}
								loading={true}
							/>
						) : (
							"Publish"
						)}
					</button>
				</div>
			</Modal>

			{/* React Modal for Creating Bid */}
			<Modal
				isOpen={showBidModal}
				style={modalCustomStyles}
				closeTimeoutMS={200}
				onRequestClose={closeBidModal}
				contentLabel='Create Bid Modal'>
				<h2 className='text-lg font-bold mb-4'>Create Bid</h2>
				<input
					type='number'
					className='w-full border border-gray-300 p-2 rounded-md mb-4'
					value={bidPrice}
					onChange={(e) => setBidPrice(e.target.value)}
					placeholder='Enter your bid price'
				/>
				<div className='flex justify-end space-x-4 mt-4'>
					<button
						className='bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded'
						onClick={closeBidModal}>
						Cancel
					</button>
					<button
						className='bg-success hover:bg-green-600 text-white py-2 px-4 rounded'
						onClick={handleBidSubmit}>
						Submit Bid
					</button>
				</div>
			</Modal>
		</div>
	);
}

export default ActionButtonsCard;
