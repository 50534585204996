import React, { useState } from "react";
import Footer from "./Footer";
import Navigation from "./Navigation";
import Partners from "./Partners";
import OurTeam from "./OurTeam";
import BannerPasswordChange from "./BannerPasswordChange";

export default function ChangeForgetPasswordPage() {
	const [scrolling, setScrolling] = useState(false);

	return (
		<>
			<section style={{ background: "#f2f3f7" }}>
				<Navigation scrolling={scrolling ? "isScrolled" : ""} />
				<BannerPasswordChange />
				{/* <OurTeam />
				<Partners /> */}
				<Footer />
			</section>
		</>
	);
}
