import React, { forwardRef } from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import Share from "assets/images/icon/svg/Share";
import { digitChanger } from "helpers/helper";
import { useSelector } from "react-redux";
import RecommendProduct from "../Home/RecommendProduct";
import ProductSearch from "../SelectProduct/ProductSearch";

const SuppliersDetails = forwardRef(
	(
		{
			supplierInfo,
			product,
			settinges,
			productCount,
			getProductBrands,
			setSearchQ,
			searchQ,
			singleProductSearch,
			setSelectedBrand,
			from,
		},
		ref // <-- Receive the ref here
	) => {
		const ekkLanguage = useSelector((state) => state.lan.lan);

		return (
			<div className='porduct-details'>
				<IonGrid>
					<IonRow className='px-2'>
						<IonCol size='12'>
							<div>
								<h5 className='font-semibold text-20 text-dark pt-2 pb-1'>
									{supplierInfo?.business_name}
								</h5>
								<p className='text-16 text-black-500 font-semibold'>
									{digitChanger(supplierInfo?.address_line)}
								</p>
								<p className='text-18 text-primary font-semibold'>
									{digitChanger(productCount?.count ?? 0)}{" "}
									{ekkLanguage.mysuppliers.skusLabel}
								</p>
							</div>
						</IonCol>
						<IonCol size='12' className='pb-6'>
							<ul className='flex justify-between'>
								<li>
									<div>
										<Share color='black' />
									</div>
								</li>
							</ul>
						</IonCol>
					</IonRow>
					<ProductSearch
						setSearchQ={setSearchQ}
						searchQ={searchQ}
						fetchProduct={getProductBrands}
						singleProductSearch={singleProductSearch}
						setSelectedBrand={setSelectedBrand}
					/>
					{/* Attach the forwarded ref to this div */}
					<div
						className={`${
							product?.length > 9 && "h-[1100px]"
						} overflow-y-auto`}
						ref={ref}>
						<RecommendProduct
							from={from}
							recommendData={product}
							title={ekkLanguage.mysuppliers.productTitleLabel}
							settinges={settinges}
						/>
					</div>
				</IonGrid>
			</div>
		);
	}
);

export default SuppliersDetails;
