import { IonLoading } from "@ionic/react";
import BottomMenu from "components/global-components/common/BottomMenu";
import BazAiNav from "components/mobile-components/Common/BazAiNav";
import BottomSheet from "components/mobile-components/Employees/BottomSheet";
import CostSummeryCard from "components/mobile-components/OrderDetails/CostSummeryCard";
import DeliveryDetailsCard from "components/mobile-components/OrderDetails/DeliveryDetailsCard";
import OrderItemCard from "components/mobile-components/OrderDetails/OrderItemCard";
import OrderMetaBar from "components/mobile-components/OrderDetails/OrderMetaBar";
import OrderOnwerState from "components/mobile-components/OrderDetails/OrderOnwerState";
import PaymentSettlementCard from "components/mobile-components/OrderDetails/PaymentSettlement";
import SelectTimeDate from "components/mobile-components/OrderDetails/SelectTimeDate";
import TimeLine from "components/mobile-components/OrderDetails/TimeLine";
import React, { useEffect, useRef, useState } from "react";
import Orders from "services/Orders";
import debounce from "lodash.debounce";
import { useSelector } from "react-redux";

export default function OrdersPage() {
	const tableRef = useRef(null);
	const [loading, setLoading] = useState(true);
	const [orders, setOrders] = useState([]);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [selectedOrder, setSelectedOrder] = useState({});
	const [collection, setCollection] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	const previousScrollPosition = useRef(0);
	const handleOrderSelect = (order, index) => {
		setSelectedOrder(order);
		setSelectedIndex(index);
	};
	const getOrders = async () => {
		setLoading(true);
		const response = await Orders.getBuyerOrderList(1, 20);
		if (response) {
			setTotalCount(response.meta.count);
			setOrders(response.data);
			setSelectedOrder(response.data[0]); // Select the first procurement
			setSelectedIndex(0);
		}
		setLoading(false);
	};
	const fetchOderCollectionAmount = async () => {
		if (true) {
			let response = await Orders.orderAmountCollection(selectedOrder?._id);
			if (response?.status === 200) {
				setCollection(response.data);
			}
		} else {
			let res = await Orders.orderAmountCollection(selectedOrder?._id);
			if (res?.status === 200) {
				setCollection(res.data);
			}
		}
	};
	useEffect(() => {
		getOrders();
		fetchOderCollectionAmount();
	}, []);
	let currentPage = 1;

	const handleScroll = debounce(async () => {
		const container = tableRef.current;
		if (!container || loading) return;

		const { scrollTop, scrollHeight, clientHeight } = container;

		// Determine scroll direction
		const isScrollingDown = scrollTop > previousScrollPosition.current;
		previousScrollPosition.current = scrollTop;

		if (isScrollingDown && scrollTop + clientHeight >= scrollHeight - 20) {
			try {
				currentPage += 1;

				const response = await Orders.getBuyerOrderList(currentPage, 20);

				if (response.status === 200) {
					setOrders((prevOrders) => [...prevOrders, ...response.data]);
				}
			} catch (error) {
				console.error("Failed to fetch orders:", error);
			} finally {
				setLoading(false);
			}
		}
	}, 300);

	// Attach scroll listener
	useEffect(() => {
		const container = tableRef.current;
		if (container) {
			container.addEventListener("scroll", handleScroll);
			return () => container.removeEventListener("scroll", handleScroll);
		}
	}, [loading]);

	return (
		<div className='px-8 pb-5 mx-auto'>
			<BazAiNav title='Orders' />
			{loading ? (
				<IonLoading
					isOpen={loading}
					message={"Please wait..."}
					spinner='circles'
				/>
			) : orders?.length === 0 ? (
				<div className=' flex justify-center items-center text-gray-500 font-bold text-2xl'>
					<div className='h-screen flex justify-center items-center text-gray-500 font-bold text-2xl'>
						<div
							className='text-center flex items-center justify-center'
							style={{ height: "calc(100vh - 420px)" }}>
							<div>
								<img
									className='m-auto mb-[10px] mt-5'
									src='/assets/images/not-found-file.png'
									alt='emptyCard'
								/>
								<p className='text-16 font-normal text-222222 mb-[35px]'>
									{ekkLanguage.orderList.notFound}
								</p>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className='w-full flex lg:flex-row flex-col space-x-5'>
					<div className='lg:w-1/2 w-full pb-2.5'>
						{/* Left Pane (Table) */}
						<div
							className='bg-[#ffffff] rounded-[10px] mt-20'
							style={{
								boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
							}}>
							{/* <h3 className='text-222222 font-bold text-24 py-2 px-4'>
								Orders
							</h3> */}
							<div
								className={`border border-gray-300 rounded-lg p-4 ${
									orders?.length > 15 && "h-[780px]"
								} overflow-y-auto`}
								ref={tableRef}>
								<div className=''>
									<table className='min-w-full divide-y divide-gray-200'>
										<thead>
											<tr className='bg-gray-500 text-white'>
												<th className='text-left px-4 py-2'>S/N</th>
												<th className='text-left px-4 py-2'>
													Order ID
												</th>
												<th className='text-left px-4 py-2'>
													Amount
												</th>
												<th className='text-left px-4 py-2'>
													Supplier
												</th>
												<th className='text-left px-4 py-2'>
													Status
												</th>
											</tr>
										</thead>
										<tbody>
											{orders?.map((order, index) => (
												<tr
													key={order?.id}
													className={`${
														selectedIndex == index
															? "bg-black-100"
															: index % 2 === 0
															? "bg-white"
															: "bg-gray-50"
													} h-12 cursor-pointer transition-colors duration-200`}
													onClick={() => {
														// setProduct([]);
														setSelectedIndex(index);
														handleOrderSelect(order, index);
													}}>
													<td className='text-black px-4 py-2'>
														{index + 1}
													</td>
													<td className='text-black px-4 py-2 text-center'>
														{order?.order_id}
													</td>
													<td className='text-black px-4 py-2 text-center'>
														{order?.total?.toFixed(2)}
													</td>
													<td className='text-black px-4 py-2'>
														{order?.seller?.business_name}
													</td>
													<td className='px-4 py-2'>
														<span
															className={`${
																order?.order_status === "Paid"
																	? "bg-00B224-1000 text-white"
																	: order?.order_status ===
																	  "Partially_Paid"
																	? "bg-00FF33-1000 text-white"
																	: order?.order_status ===
																	  "Placed"
																	? "bg-FFC227-1000 text-white"
																	: order?.order_status ===
																	  "Accepted"
																	? "bg-ADA2FF text-white"
																	: order?.order_status ===
																	  "Scheduled For Delivery"
																	? "bg-7149C6 text-white"
																	: order?.order_status ===
																	  "Delivery Failed"
																	? "bg-F27900 text-white"
																	: "bg-gray-100 text-white"
															} rounded-full px-3 py-1 text-sm`}>
															{order?.order_status
																.charAt(0)
																.toUpperCase() +
																order?.order_status.slice(1)}
														</span>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>

						{/* Right Pane (Details) */}
					</div>
					<div
						id='main-content'
						className='rounded-lg lg:w-1/2 w-full  mt-20  py-2.5 relative px-2 border bg-white border-gray-300'>
						<OrderMetaBar order={selectedOrder} />
						<section className='pt-5'>
							<OrderOnwerState order={selectedOrder} />
							<DeliveryDetailsCard order={selectedOrder} />
							<OrderItemCard order={selectedOrder} />
							<CostSummeryCard order={selectedOrder} />

							{!selectedOrder?.hasLoan && (
								<PaymentSettlementCard collection={collection} />
							)}

							<TimeLine order={selectedOrder} />
							<BottomSheet
								// orderUserType={orderUserType}
								order={selectedOrder}
								// loanDetails={loanDetails}
							/>
						</section>
					</div>
				</div>
			)}
			<BottomMenu />
		</div>
	);
}
