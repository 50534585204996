import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { clearThread, setThreadId } from "redux/container/bazAiSlice";
import Tooltip from "./Tooltip";
import BazAi from "services/BazAi";
import { getLocalUser, getUser } from "config/user";

export default function BottomMenu({
	createNewRuleHandler,
	createNewAssets,
	threadId,
	getThreadList,
}) {
	const [showIcons, setShowIcons] = useState(true);
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const user = getUser() ? getUser() : getLocalUser()?.user;
	const toggleRight = () => {
		// Give time for the panel to slide in/out before showing/hiding icons
		setTimeout(() => {
			setShowIcons(!showIcons);
		}, 300); // 300ms matches the transition duration
	};
	const createNewThread = async () => {
		let response = await BazAi.createNewThread();
		if (response.status) {
			dispatch(clearThread());
			dispatch(setThreadId(response.results.thread));
			getThreadList();
		}
	};
	return (
		<>
			{/* {pathname !== "/baz-ai" ? (
        <div
          style={{
            transition: ".3s all",
            transform: showIcons ? "rotate(135deg)" : "rotate(0deg)",
          }}
          className={`absolute flex items-center justify-center cursor-pointer w-full bg-[#d3d3d3] ${
            showIcons ? "red" : "green"
          } lg:bottom-5 lg:top-auto top-5 right-5 w-[50px] h-[50px] rounded-full `}
          onClick={toggleRight}
        >
          <h6 className="font-bold text-32">+</h6>
        </div>
      ) : (
        ""
      )} */}

			{showIcons && (
				<div
					className='fixed flex flex-col space-y-2 right-5 lg:bottom-5 bottom-24' // Added right-5 and bottom-[100px]
				>
					{/* {pathname !== "/baz-ai" ? (
						<Link to='/baz-ai'>
							<Tooltip content='Baz AI' position='left'>
								<div
									style={{
										color: "#000000",
										width: 50,
										borderRadius: "50%",
										height: 50,
										transition: "0.3s all",
										transform: showIcons
											? "translateY(0)"
											: "translateY(20px)",
										//   opacity: showIcons ? 1 : 0,
									}}
									className=' flex items-center justify-center cursor-pointer bg-d3d3d3'>
									<h6 className="font-bold text-24">M</h6>
									<img
										className='w-6 h-6'
										src='/assets/images/bot-m.png'
										alt='img'
									/>
								</div>
							</Tooltip>
						</Link>
					) : (
						""
					)} */}
					{pathname === "/baz-ai" ? (
						<Tooltip content='New Chat' position='left'>
							<div
								onClick={() => {
									createNewThread();
								}}
								style={{
									color: "#000000",
									width: 50,
									borderRadius: "50%",
									height: 50,
									transition: "0.4s all",
									transform: showIcons
										? "translateY(0)"
										: "translateY(20px)",
									//   opacity: showIcons ? 1 : 0,
								}}
								className='text-white flex items-center justify-center cursor-pointer text-30 font-bold bg-d3d3d3'>
								<img
									className='w-6 h-6'
									src='/assets/images/bazai/add.png'
									alt='img'
								/>
							</div>
						</Tooltip>
					) : (
						""
					)}
					{pathname === "/business-rules" ? (
						<Tooltip content='Create New Rule' position='left'>
							<div
								onClick={() => createNewRuleHandler()}
								style={{
									color: "#000000",
									width: 50,
									borderRadius: "50%",
									height: 50,
									transition: "0.4s all",
									transform: showIcons
										? "translateY(0)"
										: "translateY(20px)",
									//   opacity: showIcons ? 1 : 0,
								}}
								className='text-white flex items-center justify-center cursor-pointer text-30 font-bold bg-d3d3d3'>
								<img
									className='w-6 h-6'
									src='/assets/images/bazai/add.png'
									alt='img'
								/>
							</div>
						</Tooltip>
					) : (
						""
					)}
					{pathname === "/assets/" ? (
						<Tooltip content='Create New Assets' position='left'>
							<div
								onClick={() => createNewAssets()}
								style={{
									color: "#000000",
									width: 50,
									borderRadius: "50%",
									height: 50,
									transition: "0.4s all",
									transform: showIcons
										? "translateY(0)"
										: "translateY(20px)",
									//   opacity: showIcons ? 1 : 0,
								}}
								className='text-white flex items-center justify-center cursor-pointer text-30 font-bold bg-d3d3d3'>
								<img
									className='w-6 h-6'
									src='/assets/images/bazai/add.png'
									alt='img'
								/>
							</div>
						</Tooltip>
					) : (
						""
					)}
					{pathname !== "/procurements" ? (
						<Link to='/procurements'>
							<Tooltip content='Procurements' position='left'>
								<div
									style={{
										background: "#d3d3d3",
										color: "#000000",
										width: 50,
										borderRadius: "50%",
										height: 50,
										transition: "0.6s all",
										transform: showIcons
											? "translateY(0)"
											: "translateY(20px)",
										//   opacity: showIcons ? 1 : 0,
									}}
									className='flex items-center justify-center cursor-pointer'>
									<img
										className='w-6 h-6'
										src='/assets/images/bazai/supplier.png'
										alt='img'
									/>
								</div>
							</Tooltip>
						</Link>
					) : (
						""
					)}

					{pathname !== "/suppliers" ? (
						<Link to='/suppliers'>
							<Tooltip content='Suppliers' position='left'>
								<div
									style={{
										background: "#d3d3d3",
										color: "#000000",
										width: 50,
										borderRadius: "50%",
										height: 50,
										transition: "0.8s all",
										transform: showIcons
											? "translateY(0)"
											: "translateY(20px)",
										//   opacity: showIcons ? 1 : 0,
									}}
									className='flex items-center justify-center cursor-pointer'>
									<img
										className='w-6 h-6'
										src='/assets/images/bazai/supplier3.png'
										alt='img'
									/>
								</div>
							</Tooltip>
						</Link>
					) : (
						""
					)}
					{pathname !== "/orders" ? (
						<Link to='/orders'>
							<Tooltip content='Orders' position='left'>
								<div
									style={{
										color: "#000000",
										width: 50,
										borderRadius: "50%",
										height: 50,
										transition: "0.4s all",
										transform: showIcons
											? "translateY(0)"
											: "translateY(20px)",
										//   opacity: showIcons ? 1 : 0,
									}}
									className=' flex items-center justify-center cursor-pointer bg-d3d3d3'>
									{/* <h6 className="font-bold text-24">M</h6> */}
									<img
										className='w-6 h-6'
										src='/assets/images/bazai/order.png'
										alt='img'
									/>
								</div>
							</Tooltip>
						</Link>
					) : (
						""
					)}
					{pathname !== "/assets/" ? (
						<Link to='/assets/'>
							<Tooltip content='Assets' position='left'>
								<div
									style={{
										color: "#000000",
										width: 50,
										borderRadius: "50%",
										height: 50,
										transition: "0.4s all",
										transform: showIcons
											? "translateY(0)"
											: "translateY(20px)",
										//   opacity: showIcons ? 1 : 0,
									}}
									className=' flex items-center justify-center cursor-pointer bg-d3d3d3'>
									{/* <h6 className="font-bold text-24">M</h6> */}
									<img
										className='w-6 h-6'
										src='/assets/images/bazai/assets.png'
										alt='img'
									/>
								</div>
							</Tooltip>
						</Link>
					) : (
						""
					)}
				</div>
			)}
		</>
	);
}
