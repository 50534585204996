import Facebook from "assets/images/icon/svg/Facebook";
import Insta from "assets/images/icon/svg/Insta";
import Linkdin from "assets/images/icon/svg/Linkdin";
import Twitter from "assets/images/icon/svg/Twitter";
import Youtube from "assets/images/icon/svg/Youtube";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Footer() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<section className='md:pt-[10px] sm:pt-[10px] lg:pt-[0] pt-[20px] relative lg:absolute w-full bottom-0'>
			<div className='container mx-auto '>
				<div className='flex justify-center flex-wrap pt-10 pb-7'>
					<div className='pr-10'>
						{/* <img
							className='w-[150px] mt-12 mb-4 m-auto'
							src='/assets/images/ai.png'
							alt='logo'
						/> */}
						{/* <p className='text-16 text-808080 font-normal mb-7'>
							{ekkLanguage.landing.fAbout}
						</p> */}
						{/* <div className='flex'>
							<a
								href='https://www.facebook.com/EkkBazBangladesh'
								target='_blank'
								rel='noreferrer'
								className='pr-5'>
								<Facebook />
							</a>
							<a
								href='https://www.linkedin.com/company/7603237/admin/'
								target='_blank'
								rel='noreferrer'
								className='pr-5'>
								<Linkdin />
							</a>
							<a
								href='https://www.youtube.com/c/EkkBaz'
								target='_blank'
								rel='noreferrer'
								className='pr-5'>
								<Youtube />
							</a>
							<a
								href='https://www.instagram.com/ekkbaz/'
								target='_blank'
								rel='noreferrer'
								className='pr-5'>
								<Insta />
							</a>
							<a
								href='https://twitter.com/EkkBaz'
								target='_blank'
								rel='noreferrer'
								className='pr-5'>
								<Twitter />
							</a>
						</div> */}
						{/* <div className='flex items-center '>
							<a
								href='https://www.facebook.com/EkkBazBangladesh'
								target='_blank'
								className='zoom-image transform transition-transform hover:scale-110 mr-4'
								rel='noreferrer'>
								<img
									className='w-[30px] h-[30px]'
									src='/assets/images/facebook_new.png'
									alt=''
								/>
								<Facebook />
							</a>
							<a
								href='https://twitter.com/EkkBaz'
								target='_blank'
								className='zoom-image transform transition-transform hover:scale-110 mr-4'
								rel='noreferrer'>
								<img
									className='w-[30px] h-[30px]'
									src='/assets/images/twitter_new.png'
									alt=''
								/>
								<Twitter />
							</a>
							<a
								href='https://www.linkedin.com/company/7603237/admin/'
								target='_blank'
								className='zoom-image transform transition-transform hover:scale-110 mr-4'
								rel='noreferrer'>
								<img
									className='w-[30px] h-[30px]'
									src='/assets/images/linkedin_new.png'
									alt=''
								/>
								<Linkdin />
							</a>
							<a
								href='https://www.instagram.com/ekkbaz/'
								target='_blank'
								className='zoom-image transform transition-transform hover:scale-110 mr-4'
								rel='noreferrer'>
								<img
									className='w-[30px] h-[30px]'
									src='/assets/images/instagram_new.png'
									alt=''
								/>
								<Insta />
							</a>
							<a
								href='https://www.youtube.com/c/EkkBaz'
								target='_blank'
								className='zoom-image transform transition-transform hover:scale-110'
								rel='noreferrer'>
								<img
									className='w-[30px] h-[30px]'
									src='/assets/images/youtube_new.png'
									alt=''
								/>
								<Youtube />
							</a>
						</div> */}
					</div>
					{/* <div className='xl:w-2/12 lg:w-3/12 md:w-3/12 sm:w-1/2 w-1/2 pt-7'>
						<h4 className='text-18 sm:text-20 font-bold text-return mb-4'>
							{ekkLanguage.landing.location}
						</h4>
						<h6 className='text-14 sm:text-16 font-bold text-808080 mb-2'>
							USA Office
						</h6>
						<p className='text-12 sm:text-16 font-normal text-808080'>
							1111B S Governors Ave STE 3236<br></br> Dover, DE 19904,
							USA
						</p>
					</div> */}
					{/* <div className='xl:w-2/12 lg:w-3/12 md:w-3/12 sm:w-1/2 w-1/2 pt-7'>
						<h6 className='text-14 sm:text-16 font-bold text-808080 mb-2 pt-10'>
							{ekkLanguage.landing.singapore}
						</h6>
						<p className='text-12 sm:text-16 font-normal text-808080'>
							Paya Lebar Square #07-54, 60 Paya Lebar Road Singapore
							409051
						</p>
					</div>
					<div className='xl:w-2/12 lg:w-3/12 md:w-3/12 sm:w-1/2 w-1/2 pt-7'>
						<h6 className='text-14 sm:text-16 font-bold text-808080 mb-2 pt-10'>
							{ekkLanguage.landing.bangladesh}
						</h6>
						<p className='text-12 sm:text-16 font-normal text-808080'>
							{ekkLanguage.landing.bOfficeAddress}
						</p>
					</div> */}
					{/* <div className='xl:w-3/12  lg:w-6/12 md:w-6/12 sm:w-1/2 w-1/2 pt-7'>
						<h4 className='text-18 sm:text-20 font-bold text-return mb-4'>
							{ekkLanguage.landing.contactUs}
						</h4>
						<span className='block pb-[10px] text-12 sm:text-16 font-normal text-808080'>
							{ekkLanguage.landing.messageUs}:{" "}
							<a
								href='https://www.facebook.com/EkkBazBangladesh'
								target='_blank'
								rel='noreferrer'
								className='text-delivered'>
								{ekkLanguage.landing.facebook}
							</a>
						</span>
						<span className='block pb-[10px] text-12 sm:text-16 font-normal text-808080'>
							{ekkLanguage.landing.sales}:{" "}
							<a
								href='mailto:Seller@Ekkbaz.Com'
								className='text-delivered'>
								Seller@EkkBaz.com
							</a>
						</span>
						<span className='block pb-[10px] text-12 sm:text-16 font-normal text-808080'>
							{ekkLanguage.landing.support}:{" "}
							<a
								href='mailto:support@EkkBaz.com'
								className='text-delivered'>
								Support@EkkBaz.com{" "}
							</a>
						</span>
						<span className='block pb-[10px] text-12 sm:text-16 font-normal text-808080'>
							{ekkLanguage.landing.media}:{" "}
							<a
								href='mailto:Media@EkkBaz.com'
								className='text-delivered'>
								Media@EkkBaz.com{" "}
							</a>
						</span>
					</div> */}
				</div>
				<hr className='border-delivered border-2 border-top rounded-[100%] w-[97%] m-auto' />
				<div className='md:flex d-block'>
					<div className='xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full'>
						<p className='text-12 font-normal text-808080 pt-4 pb-4 md:text-left text-center'>
							Copyright @ <b>Baz AI Inc.</b> All Rights Reserved.
						</p>
					</div>
					<div className='xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full'>
						<p className='text-12 font-normal text-808080 pt-4 pb-4 md:text-right text-center'>
							1111B S Governors Ave STE 3236 Dover, DE 19904, USA
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}
