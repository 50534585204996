import { IonApp, IonContent, IonPage } from "@ionic/react";
import withLocation from "components/HOC/withLocation";
import BusinessName from "components/mobile-components/Business/BusinessName";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React from "react";
import { useSelector } from "react-redux";

function BusinessNamePage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<IonApp>
			<IonPage className='business-name'>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.businessCreateName.pageTitle}
					/>
					<BusinessName />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
export default BusinessNamePage;
